import { Component, Fragment, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import axios from "axios";
import * as mod from "./../url.js"
import { Button, Toast } from "@chakra-ui/react";

const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const navigate = useNavigate(); // For navigation after successful login

    const submitHandler = async () => {
        if (!email || !password) {
            Toast({
                title: "Please fill all the blanks.",
                status: "warning",
                duration: 500,
                isClosable: true,
                position: "bottom",
            });
            return;
        }

        setLoading(true); // Set loading to true when starting the login process
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            const response = await axios.post(
                `${mod.api_url}/auth/login`, // Adjust the URL accordingly
                { email, password },
                config
            );
            const data = response.data;
            localStorage.setItem("aspiration", JSON.stringify(data));
            Toast({
                title: "Login Successful.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
            navigate("/"); // Redirect to home page or any other page
        } catch (error) {
            Toast({
                title: "Error During Login.",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setLoading(false); // Set loading to false after the login process
        }
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title="Login Page" curPage="Login" />
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Login</h3>
                        <form className="account-form" onSubmit={e => { e.preventDefault(); submitHandler(); }}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="User email *"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password *"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="remember" id="remember" />
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                    <Link to="/forgetpass">Forget Password?</Link>
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button type="submit"
                                    colorScheme="pink"
                                    width="100%"
                                    marginTop={3}
                                    onClick={submitHandler}
                                    isLoading={loading}
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                        {/* Uncomment and adjust if needed */}
                        {/* <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account?  <Link to="/signup">Sign Up</Link></span>
                            <span className="or"><span>or</span></span>
                            <h5 className="subtitle">{socialTitle}</h5>
                            <ul className="lab-ul social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default LoginPage;