import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { Image, useToast } from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../url";

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const fetchBlogs = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${mod.api_url}/api/v1/blogs/get_all`, {
                params: { page, limit: 6 }, // Assuming 9 items per page
            });
            setBlogs(response?.data?.blogs || []);
            setTotalPages(response?.data?.totalPages || 1);
            setCurrentPage(page);
        } catch (error) {
            console.error("Error fetching blogs:", error);
            toast({
                title: "Error fetching blogs.",
                description: error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleUpdate = (slug) => {
        navigate(`/update-blogs/${slug}`);
    };

    const readBlog = (slug) => {
        navigate(`/read-blog/${slug}`);
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Blog Posts'} curPage={'Blog'} />
            <div className="blog-section padding-tb section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                                {blogs.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="post-item">
                                            <div className="post-inner">
                                                <div className="post-thumb">
                                                    <Link to={`/blog-single/${val.slug}`}>
                                                        <img
                                                            src={
                                                                `${mod.api_url}/api/v1/blogs/singlePhoto/${val._id}` ||
                                                                "placeholder.jpg"
                                                            }
                                                            alt="Blog Thumbnail"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="post-content">
                                                    <Link to={`/blog-single/${val.slug}`}>
                                                        <h4>{val.heading.substring(0, 50)}</h4>
                                                    </Link>
                                                    <p>{val.mainContent.substring(0, 100)}</p>
                                                </div>
                                                <div className="post-footer">
                                                    <div className="pf-left">
                                                        <Link to={`/blog-single/${val.slug}`} className="lab-btn-text">
                                                            {val.createdAt.substring(0, 10)} <i className="icofont-external-link"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="pf-right">
                                                        <i className="icofont-comment"></i>
                                                        <span className="comment-count">{val.commentCount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* Pagination */}
                        <div className="pagination m-5" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                style={{ padding: "8px 16px" }}
                            >
                                Previous
                            </button>
                            {[...Array(totalPages)].map((_, i) => (
                                <button
                                    key={i}
                                    className={currentPage === i + 1 ? "active" : ""}
                                    onClick={() => handlePageChange(i + 1)}
                                    style={{
                                        padding: "8px 16px",
                                        backgroundColor: currentPage === i + 1 ? "#007bff" : "#f1f1f1",
                                        color: currentPage === i + 1 ? "#fff" : "#000",
                                        border: "1px solid #ddd",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                style={{ padding: "8px 16px" }}
                            >
                                Next
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default BlogPage;
