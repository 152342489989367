import React, { Fragment, useState } from 'react';
import axios from 'axios';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Footer from '../component/layout/footer';
import * as mod from "./../url"
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@chakra-ui/react';

function RegistrationForm() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    document: null,
    degree: '',
    vocation: '',
    bachelorsSpecialisation: '',
    mastersSpecialisation: '',
    areaOfInterest: '',
    others: '',
    bachelorsScore: '',
    mastersScore: '',
    workExperience: '',
    startStudyDate: '',
    germanProgram: '',
    universityType: '',
    languageSkills: '',
    EnglishProficiency: '',
    GermanProficiency: ''

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, document: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    if (!isChecked) {
      setError("You must agree to the terms before submitting.");
    } else {
      e.preventDefault();
      const data = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        if (key === "document") {
          data.append("document", formData.document);
        } else {
          data.append(key, formData[key]);
        }
      });

      try {
        const response = await axios.post(`${mod.api_url}/api/v1/user/form_create`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // console.log('Registration successful:', response.data);
        alert('Registration submitted successfully!');
        navigate('/');
      } catch (error) {
        console.error('Error submitting registration:', error);
        alert('There was an error submitting the registration. Please try again.');
      }
    }
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title="Profile Evaluation Form" curPage="Registration Form" />
      <Container className="p-5 shadow-lg bg-white rounded" style={{ maxWidth: '800px' }}>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="phone" className="mb-3">
                <Form.Label>Phone Number*</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="document" className="mb-3">
                <Form.Label>Upload Document (CV / Resume)</Form.Label>
                <Form.Control
                  type="file"
                  name="document"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="degree" className="mb-3">
                <Form.Label>Degree of Interest</Form.Label>
                <Form.Select
                  name="degree"
                  value={formData.degree}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="bachelors"> Bachelor's </option>
                  <option value="masters"> Master's </option>
                  <option value="phd"> PHD </option>
                  <option value="others"> Others </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="vocation" className="mb-3">
                <Form.Label>Interested in Ausbildung/Vocational Training?</Form.Label>
                <Form.Select
                  name="vocation"
                  value={formData.vocation}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="bachelorsSpecialisation" className="mb-3">
                <Form.Label>Bachelor’s Specialization</Form.Label>
                <Form.Control
                  type="text"
                  name="bachelorsSpecialisation"
                  value={formData.bachelorsSpecialisation}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="mastersSpecialisation" className="mb-3">
                <Form.Label>Master’s Specialization</Form.Label>
                <Form.Control
                  type="text"
                  name="mastersSpecialisation"
                  value={formData.mastersSpecialisation}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Group controlId="areaOfInterest" className="mb-3">
              <Form.Label>Area of Interest for Higher Education</Form.Label>
              <Form.Control
                type="text"
                name="areaOfInterest"
                value={formData.areaOfInterest}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="workExperience" className="mb-3">
              <Form.Label>Work Experience (if any)</Form.Label>
              <Form.Control
                as="textarea"
                name="workExperience"
                value={formData.workExperience}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Form.Group controlId="languageSkills" className="mb-3">
            {/* <Form.Label>Language Skills</Form.Label> */}
            <Row>
              <Col md={6}>
                <Form.Group controlId="bachelorsScore" className="mb-3">
                  <Form.Label>Score of bachelors</Form.Label>
                  <Form.Control
                    type="text"
                    name="bachelorsScore"
                    value={formData.bachelorsScore}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="mastersScore" className="mb-3">
                  <Form.Label>Score of masters </Form.Label>
                  <Form.Control
                    type="text"
                    name="mastersScore"
                    value={formData.mastersScore}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="English Proficiency" className="mb-3">
                  <Form.Label>English Proficiency</Form.Label>
                  <Form.Control
                    type="text"
                    name="English Proficiency"
                    value={formData.EnglishProficiency}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="German Proficiency" className="mb-3">
                  <Form.Label>German Proficiency </Form.Label>
                  <Form.Control
                    type="text"
                    name="German Proficiency"
                    value={formData.GermanProficiency}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="languageSkills" className="mb-3">
                  <Form.Label>Course language</Form.Label>
                  <Form.Select
                    name="languageSkills"
                    value={formData.languageSkills}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="German">German only</option>
                    <option value="English">English only </option>
                    <option value="German and english">German and english </option>
                    <option value="Others">Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="universityType" className="mb-3">
                  <Form.Label>Looking for Universities</Form.Label>
                  <Form.Select
                    name="universityType"
                    value={formData.universityType}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Public">Public </option>
                    <option value="Private">Private </option>
                    <option value="Both">Both </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="startStudyDate" className="mb-3">
                  <Form.Label>Tentative start of studying in Germany?
                    Year and month</Form.Label>
                  <Form.Control
                    type="date"
                    name="startStudyDate"
                    value={formData.startStudyDate}
                    onChange={handleChange}
                    required
                  />

                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="germanProgram" className="mb-3">
                  <Form.Label>Are you open to study in German taught Programs?</Form.Label>
                  <Form.Select
                    name="germanProgram"
                    value={formData.germanProgram}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes </option>
                    <option value="No">No </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I Agree to receive information and calls from Aspirations Inc.
          </Checkbox>
          <Row className="justify-content-center m-2">
            <Button variant="primary" type="submit" className="w-50">
              Submit
            </Button>
          </Row>

        </Form>
      </Container>
      <Footer />
    </Fragment>
  );
}

export default RegistrationForm;
