import { Button } from 'bootstrap';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const subTitle = "SOLID FEATURES";
const title = "Our areas of expertise";

const categoryList = [
    {
        imgUrl: 'assets/images/category/n2.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: "Personalised Consultation",
        des: 'We provide customised solutions based on your career choices, evaluation of your qualifications, check the apt academic programmes in different universities. We thoroughly examine your Scholarship eligibility, define the cost and criteria for studies in Germany and provide a complete comprehensive support.',
    },
    {
        imgUrl: 'assets/images/category/n1.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: "Application Assistance",
        des: 'Here we put a check-list on all the pre-requisite, criteria and documentation you need in order to get admission in the best universities and institutes in Germany. After assessing your previous qualifications, degrees and certificates we will check on the right universities and institutes you are fit for. Next, we cover step-by-step in preparing application documents, Letter of Recommendation (LOR), Statement of Purposes (SOP) and work on much more in minute details.',
    },
    {
        imgUrl: 'assets/images/category/n5.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Visa and Immigration Support',
        des: 'We solve one of the complex task of getting Visa and going through the immigration process in order to study in Germany! We provide assistance in your application and documentation procedures, Visa interview preparation, and other expenses, alongside proof of no-criminal record, health insurance, biometrics, language skills, accommodation and other concerns. We help you stay legally safe and secured, so that you focus on your academics hassle-free!',
    },
    {
        imgUrl: 'assets/images/category/n4.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Pre-Departure Orientation',
        des: 'If you are worrying about how to study in Germany, or how to live and accommodate in Germany, then we have pre-departure orientation as well. ASPIRATIONS INC. provides the utmost guidance to students who are leaving for Germany to pursue higher education. We make sure you are well-versed and equipped with enough sources on banking, health insurance, culture, food, laws, safety-measures, work allowance for students, student insurance, student organisations, immigration rules and a plenty of regulations that you need in order to go to Germany as an Indian student!',
    },
    {
        imgUrl: 'assets/images/category/n3.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'All-inclusive admission support ',
        des: 'ASPIRATIONS INC. ensure you a comprehensive admission support with 100% success rate. We curate the appropriate choice of universities aligning with your previous academia and expertise and ensure you get a full admission assistance in the quest to get into the best universities of Germany. Our expert consultants deep-dive into every student’s personal and professional needs and cater a tailor-made assistance, ensuring students’ accommodation, and suitable standard environment. We connect the student to higher officials of the University, and give all the emergency contact details to avail in need.',
    },
];

const Category = () => {
    // const [expanded, setExpanded] = useState(Array(categoryList.length).fill(false));
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    const [isExpanded4, setIsExpanded4] = useState(false);
    const [isExpanded5, setIsExpanded5] = useState(false);


    return (
        <div className="category-section py-5">
            <div className="container">
                <div className="section-header text-center mb-5" data-aos="fade-left" data-aos-duration="1000">
                    <h2 className="title">{title}</h2>
                    {/* <hr /> */}
                    {/* <p>Study in Germany consultants assist you in selecting the finest courses for studying in Germany<br /> and guide effective preparations and arrangements.</p> */}
                </div>
                <div className="section-wrapper">
                    <div className="row g-3 justify-content-center">

                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="category-item text-center">
                                <div className="category-inner p-3">
                                    <div className="icon-wrapper mx-auto" style={{ width: 101 }} data-aos="fade-right" data-aos-duration="1000">
                                        <img src='assets/images/category/n2.jpg' alt='category rajibraj91 rajibraj' className="img-fluid" />
                                    </div>
                                    <h5 className="category-content  mt-3" style={{ textAlign: "left", color: "black" }}>
                                        Personalised Consultation
                                    </h5>
                                    <p style={{ textAlign: "left", color: "gray" }}>
                                        {isExpanded1
                                            ? "We provide customised solutions based on your career choices, evaluation of your qualifications, check the apt academic programmes in different universities. We thoroughly examine your Scholarship eligibility, define the cost and criteria for studies in Germany and provide a complete comprehensive support."
                                            : "We provide customised solutions based on your career choices, evaluation of your qualifications, check the..."}
                                    </p>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setIsExpanded1(!isExpanded1)}
                                    >
                                        {isExpanded1 ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="category-item text-center">
                                <div className="category-inner p-3">
                                    <div className="icon-wrapper mx-auto" style={{ width: 101 }} data-aos="fade-right" data-aos-duration="1000">
                                        <img src='assets/images/category/n1.jpg' alt='category rajibraj91 rajibraj' className="img-fluid" />
                                    </div>
                                    <h5 className="category-content  mt-3" style={{ textAlign: "left", color: "black" }}>
                                        Application Assistance
                                    </h5>
                                    <p style={{ textAlign: "left", color: "gray" }}>
                                        {isExpanded2
                                            ? (<>
                                                Here we put a check-list on all the pre-requisite, criteria and documentation you need in order to get admission in the <strong>best universities and institutes in Germany</strong>. After assessing your previous qualifications, degrees and certificates we will check on the right universities and institutes you are eligible for. Next, we cover step-by-step in preparing application documents, Letter of Recommendation (LOR), Statement of Purposes (SOP) and work on much more in minute details.
                                            </>)
                                            : "Here we put a check-list on all the pre-requisite, criteria and documentation you need in order to get admission..."}
                                    </p>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setIsExpanded2(!isExpanded2)}
                                    >
                                        {isExpanded2 ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="category-item text-center">
                                <div className="category-inner p-3">
                                    <div className="icon-wrapper mx-auto" style={{ width: 101 }} data-aos="fade-right" data-aos-duration="1000">
                                        <img src='assets/images/category/n5.jpg' alt='category rajibraj91 rajibraj' className="img-fluid" />
                                    </div>
                                    <h5 className="category-content  mt-3" style={{ textAlign: "left", color: "black" }}>
                                        Visa and Immigration Support
                                    </h5>
                                    <p style={{ textAlign: "left", color: "gray" }}>
                                        {isExpanded3
                                            ? (
                                                <>
                                                    We solve one of the complex task of getting Visa and going through the <b >
                                                        immigration process in order to study in Germany!</b> We provide assistance in your application and documentation procedures, Visa interview preparation, and other expenses, alongside proof of no-criminal record, health insurance, biometrics, language skills, accommodation and other concerns. We help you stay legally safe and secured, so that you focus on your academics hassle-free!
                                                </>
                                            )
                                            : "We solve one of the complex task of getting Visa and going through the immigration process in order to study..."}
                                    </p>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setIsExpanded3(!isExpanded3)}
                                    >
                                        {isExpanded3 ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Card 4 */}
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="category-item text-center">
                                <div className="category-inner p-3">
                                    <div className="icon-wrapper mx-auto" style={{ width: 101 }} data-aos="fade-right" data-aos-duration="1000">
                                        <img src='assets/images/category/n4.jpg' alt='category rajibraj91 rajibraj' className="img-fluid" />
                                    </div>
                                    <h5 className="category-content  mt-3" style={{ textAlign: "left", color: "black" }}>
                                        Pre-Departure Orientation
                                    </h5>
                                    <p style={{ textAlign: "left", color: "gray" }}>
                                        {isExpanded4
                                            ? (<>
                                                If you are worrying about <b>how to study in Germany</b>, or how to live and accommodate in Germany, then we have pre-departure orientation as well. ASPIRATIONS INC. provides the utmost guidance to students who are leaving for Germany to pursue higher education. We make sure you are well-versed and equipped with enough sources on banking, health insurance, culture, food, laws, safety-measures, work allowance for students, student insurance, student organisations, immigration rules and a plenty of regulations that you need in order to go to Germany as an Indian student!
                                            </>)
                                            : (<>
                                                If you are worrying about <b>how to study in Germany</b>, or how to live and accommodate in Germany, then.......
                                            </>)}
                                    </p>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setIsExpanded4(!isExpanded4)}
                                    >
                                        {isExpanded4 ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Card 5 */}
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="category-item text-center">
                                <div className="category-inner p-3">
                                    <div className="icon-wrapper mx-auto" style={{ width: 101 }} data-aos="fade-right" data-aos-duration="1000">
                                        <img src='assets/images/category/n3.jpg' alt='category rajibraj91 rajibraj' className="img-fluid" />
                                    </div>
                                    <h5 className="category-content  mt-3" style={{ textAlign: "left", color: "black" }}>
                                        All-inclusive admission support
                                    </h5>
                                    <p style={{ textAlign: "left", color: "gray" }}>
                                        {isExpanded5
                                            ? "ASPIRATIONS INC. ensure you a comprehensive admission support with 100% success rate. We curate the appropriate choice of universities aligning with your previous academia and expertise and ensure you get a full admission assistance in the quest to get into the best universities of Germany. Our expert consultants deep-dive into every student’s personal and professional needs and cater a tailor-made assistance, ensuring students’ accommodation, and suitable standard environment. We connect the student to higher officials of the University, and give all the emergency contact details to avail in need."
                                            : "ASPIRATIONS INC. ensure you a comprehensive admission support with 100% success rate. We curate..."}
                                    </p>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setIsExpanded5(!isExpanded5)}
                                    >
                                        {isExpanded5 ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;
