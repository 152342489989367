import React, { useState } from 'react'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
const title = "Advanced Adobe Photoshop For Everyone";
const desc = "The most impressive is collection of share me online college courses";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const Finance_In_Germany = () => {
    const [openFAQ, setOpenFAQ] = useState(null); // Track the open FAQ

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ by index
    };
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/germany-job-seeker-visa.jpg" alt="germany-job-seeker-visa" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <div className="bachelors-page">
                                                <header className="bachelors-header">
                                                    <h1>Bachelor’s Degree in Germany</h1>
                                                    <p>
                                                        Germany offers top-notch education with minimal fees, and it's becoming an increasingly popular choice for international students.
                                                    </p>
                                                </header>

                                                <section className="why-germany">
                                                    <h4>Why Should Indian Students Study a Bachelor's in Germany?</h4>
                                                    <ul>
                                                        <li>Highest Quality Education with practical learning.</li>
                                                        <li>Minimal Education Fees in public universities.</li>
                                                        <li>Countless Course Options in different fields.</li>
                                                        <li>Employment and research opportunities during and after studies.</li>
                                                        <li>Growing Indian and international community.</li>
                                                    </ul>
                                                </section>

                                                <section className="programs-offered">
                                                    <h4>Programs Offered</h4>
                                                    <p>
                                                        German universities offer a wide range of Bachelor's degree programs such as:
                                                    </p>
                                                    <ul>
                                                        <li>Music Business</li>
                                                        <li>Game Design</li>
                                                        <li>International Business Management</li>
                                                        <li>Photography</li>
                                                        <li>User Experience Design</li>
                                                        <li>...and many more!</li>
                                                    </ul>
                                                    <p>With over 17,000 courses to choose from, students have endless opportunities to pursue their academic interests.</p>
                                                </section>

                                                <section className="admission-requirements">
                                                    <h4>Admission Requirements</h4>
                                                    <ol>
                                                        <li>10th and 12th standard Marksheets and Certificates</li>
                                                        <li>High School Diploma (translated into German)</li>
                                                        <li>IELTS or TOEFL Score</li>
                                                        <li>TestAS Score</li>
                                                        <li>Letter of Recommendation (LOR)</li>
                                                        <li>Statement of Purpose (SOP)</li>
                                                        <li>Blocked Account with €11,208</li>
                                                        <li>Health Insurance Proof</li>
                                                        <li>Visa Documents</li>
                                                    </ol>
                                                </section>

                                                <section className="visa-requirements">
                                                    <h4>Visa Requirements</h4>
                                                    <p>
                                                        International students, especially from India, need to apply for a student visa. You may need a Schengen (C Visa) for short-term studies or a German National Visa (D Visa) for longer programs.
                                                    </p>
                                                </section>

                                                <section className="aps-verification">
                                                    <h4>APS Verification</h4>
                                                    <p>APS Verification is mandatory for Indian students applying for Bachelor's programs in Germany. The process takes 3-4 weeks.</p>
                                                </section>

                                                <section className="frequently-asked-questions">
                                                    <h4>Frequently Asked Questions (FAQs)</h4>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(1)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 1 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Is Germany a good country for a Bachelor’s program?
                                                        </h6>
                                                        {openFAQ === 1 && (
                                                            <p>Yes, Germany is among the most popular countries for international students.</p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(2)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 2 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What is the cost of studying?
                                                        </h6>
                                                        {openFAQ === 2 && (
                                                            <p>Most public universities have no tuition fees, but students pay a nominal administrative fee.</p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(3)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 3 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can students work while studying?
                                                        </h6>
                                                        {openFAQ === 3 && (
                                                            <p>Yes, students can work part-time or full-time during semester breaks.</p>
                                                        )}
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
