import { useEffect, useState } from "react";
import PopupForm from './popup';
import { Link, Navigate } from "react-router-dom";
import { Box, Center, Text, keyframes } from "@chakra-ui/react";
import * as mod from "./../../url";
import Whatsapp from "../layout/WhatsappLogo";
import AOS from 'aos';  // Import AOS library
import 'aos/dist/aos.css';  // Import AOS CSS
// import './Banner.css';
import sampleVideo from "./../../assets/images/banner/home1.mp4";
//import sampleVideo from "./../images/home1.mp4";

const subTitle = " NAMASTE INDIA ! GERMANY WELCOMES YOU FOR YOUR HIGHER EDUCATION";
const title = <h4 className="title"><span className="d-lg-block">The starting point for your German career</span> </h4>;
const desc = " DO YOU ASPIRE TO STUDY IN GERMANY ? We are a group of education advisors providing holistic solution centric guidance for students Know all legal procedures, check your eligibility, choose right programme……………. and fly to Germany Our core services that we provide are: (1)Pre - Consultation, (2) APS Verification(5) Visa and Immigration Support, (3)Application Procedure, (4) Admission and Post - Admission Assistance ensures success and a smooth student life in Germany Fly high with WINGS! ";


const shapeList = [
    {
        name: '16M Students Happy',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: '130K+ Total Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: '23M+ Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        name: '36+ Languages',
        link: '#',
        className: 'ccl-shape shape-5',
    },
]

const Banner = () => {
    const [showPopup, setShowPopup] = useState(false);


    const whatsappNumber = "+919310072343"; // Replace with your phone number

    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("Hello! I need assistance.");
        const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };
    useEffect(() => {
        AOS.init(); // Initialize AOS library
    }, []);

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleFormSubmit = (data) => {
        console.log('Form submitted:', data);
        handleClosePopup();
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                handleOpenPopup();
                window.removeEventListener('scroll', handleScroll);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const moveLeftRight = keyframes`
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  `;
    return (
        <>
            <section className="banner-section" style={{ padding: '40px 0', margin: '0', background : '#e7e6e9' }}>
                <div className="container">
                    <div className="section-wrapper" style={{ overflow: 'hidden' }}>
                        <div className="row align-items-center" style={{ margin: '0', marginTop: '120px' }}>
                            {/* Left Section */}
                            <div className="col-xxl-6 col-xl-6" data-aos="fade-right" data-aos-duration="1000">
                                <div className="banner-content" style={{ maxWidth: '600px' }}>
                                    <br/>
                                    <h6 className="subtitle text-uppercase fw-medium" style={{ color: 'gray' }}>
                                    NAMASTE INDIA!
                                </h6>
                                    {/*<Box
                                        width="100%"
                                        overflow="hidden"
                                        bg="gray.100"
                                        py={4}
                                        display="flex"
                                        justifyContent="center"
                                    >

                                        <Text
                                            fontSize="1.5rem"
                                            marginBottom="0px"
                                            fontWeight="bold"
                                            color="orange"
                                            display="inline-block"
                                            animation={`${moveLeftRight} 6s linear infinite alternate`}
                                            textAlign="center"
                                            bgGradient="linear(to-r, orange, white, green, pink, blue)"
                                            bgClip="text"
                                            // height='10px'

                                            backgroundSize="200% 100%"
                                            backgroundPosition="0% 50%"
                                            style={{
                                                WebkitBackgroundClip: 'text',
                                            }}
                                            _hover={{
                                                transform: "scale(1.1)",
                                                transition: "transform 0.3s ease-in-out",
                                            }}
                                        >
                                            <strong
                                                className="subtitle text-uppercase"
                                                style={{
                                                    WebkitBackgroundClip: 'text',
                                                    backgroundClip: 'text',
                                                    color: 'transparent',
                                                    backgroundSize: '400% 100%',
                                                    marginTop: '15px',
                                                    padding: '0px',
                                                    fontSize: '1.5rem', 
                                                }}
                                            >
                                                NAMASTE INDIA!
                                            </strong>
                                        </Text>

                                    </Box>*/}
                                    <h4>
                                        GERMANY WELCOMES YOU FOR YOUR HIGHER EDUCATION
                                    </h4>
                                    <p><b>DO YOU ASPIRE TO STUDY IN GERMANY!?</b></p>
                                    <p><b>Willkommen in Deutschland! You are at the right place – we’ve got your back.</b></p>
                                    <p className="desc m-1" style={{ marginBottom: '1.5rem', fontSize: '1rem' }}>
                                        We are a group of education advisors providing holistic solution-centric guidance for students.<br /><br />
                                        Know all legal procedures, check your eligibility, choose the right programme… and fly to Germany.<br /><br/>
                                        Our core services that we provide are: Pre-Consultation, APS Verification, Application Procedure, Visa and Immigration Support, Admission, and Post-Admission Assistance ensure a smooth student life in Germany.<br /><br />
                                        Fly high with WINGS!
                                        <br />
                                    </p>

                                    <Link to={''} className="btn btn-secondary m-2 p-2" onClick={handleWhatsAppClick} style={{ transition: 'background-color 0.3s', color: 'white' }}>
                                        Connect me with an Expert
                                    </Link>
                                    <Link to='/register' className="btn btn-secondary m-2 p-2" style={{ transition: 'background-color 0.3s', color: 'white' }}>
                                        Free Profile Evaluation
                                    </Link>
                                </div>
                            </div>

                            {/* Right Section */}
                            <div className="col-xxl-6 col-xl-6" data-aos="fade-left" data-aos-duration="1000">
                                <div className="banner-thumb" style={{ textAlign: 'center' }}>
                                    <video width="100%" height="auto" autoPlay loop muted playsInline>
                                        <source src={sampleVideo} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popup Form triggered on button click or scroll */}
                <PopupForm show={showPopup} onClose={handleClosePopup} onSubmit={handleFormSubmit} />
                <Whatsapp />
            </section>
        </>
    );


};

export default Banner;
