import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios for API requests
import * as mod from "./../../url"; // Assuming your API URL is defined in this file
import { useToast } from '@chakra-ui/react'; // Assuming you're using Chakra UI for notifications

const PopupForm = ({ show, onClose, onSubmit }) => {
    const [isAgreed, setIsAgreed] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        state: '',
        message: '',

    });

    const toast = useToast(); // For showing success/error messages

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling when popup is open
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling when popup is closed
        }
        return () => {
            document.body.style.overflow = 'auto'; // Reset on cleanup
        };
    }, [show]);

    if (!show) {
        return null;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const submit = async (event) => {
        event.preventDefault(); // Prevent form from submitting

        try {
            // Send form data to the backend API
            const response = await axios.post(`${mod.api_url}/api/v1/contact/submit_details`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                toast({
                    title: "Success",
                    description: "Your contact details were submitted successfully!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                // Clear the form data after successful submission
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    state: '',
                    message: ''
                });

                // Optional: Call parent onSubmit handler
                if (onSubmit) {
                    onSubmit(formData);
                }
            }
            onClose();
        } catch (error) {
            console.error('Error submitting contact details:', error);

            toast({
                title: "Error",
                description: "There was an issue submitting your contact details. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <button onClick={onClose} style={styles.closeButton}>X</button>
                <form style={styles.form} onSubmit={submit}>
                    <h4 style={styles.heading}>Submit Your Enquiry</h4>
                    {Object.entries(formData).map(([key, value]) => (
                        <label style={styles.label} key={key}>
                            {key === "message" ? (
                                <textarea
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    required
                                    style={styles.input}
                                    placeholder={` Your ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    rows="4" // Set the height for the message textarea
                                />
                            ) : (
                                <input
                                    type={key === "email" ? "email" : "text"}
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    required
                                    style={styles.input}
                                    placeholder={` Your ${key.charAt(0).toUpperCase() + key.slice(1)}*`}
                                />
                            )}
                        </label>
                    ))}
                    <div className=" row align-items-center">
                        <div className="col-auto">
                            <input
                                type="checkbox"
                                checked={isAgreed}
                                onChange={(e) => setIsAgreed(e.target.checked)}
                                className=""
                                required
                            />
                        </div>
                        <div className="col m-2">
                            <span className="agreement-text"> I Agree to receive information and calls from Aspirations Inc.</span>
                        </div>
                    </div>
                    <button type="submit" style={styles.submitButton}>Submit</button>
                </form>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        overflow: 'hidden', // Prevent scrolling on the overlay
    },
    popup: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width: '90%',
        maxWidth: '400px',
        maxHeight: '90vh', // Prevent popup from exceeding viewport height
        overflowY: 'auto', // Enable vertical scrolling if needed
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',
        background: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    label: {
        width: '100%',
        marginBottom: '1px',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
        outline: 'none',
        transition: 'box-shadow 0.3s ease',
    },
    submitButton: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007BFF',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    },
};

export default PopupForm;
