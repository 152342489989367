import React, { useState } from 'react'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
const title = "Vocational Training in Germany for Indian Students";
const desc = "Germany offers strong vocational training that is open to both national and international students. Indian students can opt for vocational training in Germany after fulfilling specific criteria. The vocational training in Germany, also known as 'Ausbildung ', is a highly structured program that provides learners with hands-on practical training, which helps improve skills and introduces students to a work atmosphere. This training often combines classroom learning and practical experience in live projects, giving students and job seekers a pragmatic approach to handling a job role.";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const Vocational_In_Germany = () => {
    const [openFAQ, setOpenFAQ] = useState(null); // Track which FAQ is open

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ by index
    };
    return (
        <><Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/vocational-training-in-germoney.jpg" alt="vocational-training-in-germoney" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <section className="benefits">
                                                <p>The skills acquired during vocational training in Germany open doors to a multitude of international employers. The possibility of work opportunities increases after upscaling and upskilling from these vocational training programs, providing students with a sense of ambition and hope for their future careers.</p>
                                                <p>Vocational training provides young students and professionals with valuable skills and hands-on work experience. This experience is highly regarded by many recruiters, giving these professionals a competitive edge in the job market. The skills and workdays accumulated during vocational training are counted as work experience, making these professionals more competent and desirable to employers.</p>
                                                
                                                <h4>What are the benefits of doing vocational training in Germany?</h4>
                                                <p>
                                                <b>Employment opportunities:</b>When students pursue vocational training in Germany, they gain theoretical knowledge and practical skills necessary for jobs. More than 80% of large companies hire apprentices, and around 500,000 apprenticeship job positions are vacant every year. Students pursue vocational training in Germany at the minimum age of 16, but for international students, the minimum Age is 18 to meet all the legal and official standards. This means that students after their 10th Grade, and mostly after their 12th Grade, can opt for vocational training in Germany.
                                                </p>
                                                <p><b>Free education:</b> Most of the vocational training programs are free for students. Instead, they receive a stipend from their employers during the training.
                                                </p>
                                                <p><b>Employment Security:</b> The demands of recession-resistant jobs, such as skilled and technical jobs, stay higher throughout the year. Many industries require experienced professionals in these kinds of job roles, and these professions are less exposed to economic downturns. Hence, job security remains prevalent.  STEM literacy is vital for success in many vocational fields in Germany because the country is highly industrialised and technologically advanced. Professions such as robotics, 3D printing, nanotechnology, and IT require strong STEM skills. The dual vocational training system ensures that apprentices gain the necessary STEM knowledge and practical skills for their chosen careers.
                                                </p>
                                                <p><b>Upskill and Outreach for Professionals:</b> Vocational training is not limited to entry-level positions or for students. Many professionals also take up vocational training and boost their skills for better career advancement. If skilled people wish to shift their jobs towards a specialised domain, they can pursue vocational training from Germany and lead their career pathway!
                                                </p>
                                                <p>The vocational training program provides students with certificates and work experience in their field of study. The programs, theoretical knowledge, and practical experience have played a vital role in Germany's market and economy for ages. The vocational training program in Germany provides a strong foothold for students and career prospects.
                                                </p>
                                                <h5 className='m-0'>Types of vocational training in Germany for international students </h5>
                                                <p>International students, including Indian students, are allowed to pursue vocational training in Germany. For international students, vocational training generally falls under two categories: 1) Dual vocational training and 2) School-based vocational training
                                                </p>
                                                <b>Dual Vocational Training (Duale Ausbildung)</b>
                                                <p>The dual vocational training system incorporates simultaneous learning of theoretical knowledge and skills for a practical approach.<br/> 
                                                In the dual system, the public vocational School, controlled by the federal state, cooperates with large private companies controlled by the Federal government, which offer vocational training to German and international students. While practical work with training is provided within the companies, theoretical education is taught at the vocational schools (Berufsschule)</p>
                                                <b>Some common traits of dual vocational training:</b>
                                                <ul>
                                                    <li><b>Training structure:</b> Students typically spend 40% of their time in the vocational school, whereas the rest 60% of their time in the workplace.</li>

                                                    <li><b>Duration of the training:</b> The dual vocational training generally lasts between 2 and 3 years and often up to 3 and a half years. </li>

                                                    <li><b>Remuneration:</b> Students receive their salary during their vocational training as trainees. The remuneration depends upon the occupation and the type of work </li>
                                                </ul>
                                                <h5 className='m-0'>School-based vocational training (Schulische Ausbildung)</h5>
                                                School-based vocational training focuses more on the theoretical approach and knowledge acquisition than practical hands-on experience. This kind of vocational training is conducted in vocational schools known as Berufsfachschule or Berufskolleg.
                                                <br />
                                                <br />
                                                <b>Key features of school-based vocational training: </b>
                                                <ul>
                                                    <li>   <b>Structure of the training:</b> The focus is more on theoretical knowledge, while on-the-job training is limited. The goal is to clarify the foundation. Mainly, industries like healthcare, business, design, etc., implement school-based vocational training. </li>

                                                    <li><b>Duration of school-based vocational training:</b> This kind of school-based vocational training usually lasts one to three years. </li>

                                                    <li><b>Remuneration:</b> Generally, students don't receive a salary. If they do, they receive a limited salary for this kind of training. However, in specific fields like healthcare, the trainees can earn a stipend when they are working at nursing homes or hospitals.</li>

                                                </ul>
                                                <h5 className='m-0'>Dual vocational degree programs (Ausbildungsintegriertes duales Studium)</h5>
                                                <p>
                                                    Dual vocational degree programs include practical training with higher academic education. Students can have a bachelor's degree from a recognised university and training qualifications—both at the same time. This training lasts a bit longer, for 3 to 5 years, to get the best of both worlds: university lectures on theories and practical experience! This course not only clears students' foundational base but also gives them hands-on experience, preparing them for a better job after completing this training
                                                    <br /><br />
                                                    <b>Duration of this program:</b> This program generally lasts 3 to 5 years and depends upon the subject chosen and the criteria of the university offering it.
                                                    <br /><br />
                                                    <b>Eligibility for the course:</b> Students require the necessary entrance qualifications to get into the universities in Germany
                                                    <br /><br />
                                                    <b>Structure of the program:</b> Students are involved in higher academic studies, which is mainly the theory portion, and they also receive practical training during this program
                                                    <br /><br />
                                                    <b>Remuneration:</b> In some programs, students receive a salary during the training.
                                                </p>
                                            </section>

                                            <section className="types-of-training">
                                                <h4 className='m-0'>Duration of Vocational Training in Germany</h4>
                                                <p><a href="#">Vocational training in Germany</a> lasts mainly 2 to 3 years, depending on a person's chosen profession. <br />
                                                    Dual vocational training generally ranges from 2 to 3 years (sometimes three and a half years), as it is well structured with theoretical knowledge and practical experience. <br />
                                                    Meanwhile, accelerated programs mainly offer shorter training programs that can be completed in less than two years. <br />
                                                    However, school-based vocational training can range from 1 to 3 years, whereas dual vocational degree programs last for 3 to 5 years. </p>

                                                <h5 className='m-0'>Language Criteria</h5>
                                                <p><b>Language Proficiency:</b> Language proficiency in German and English is most important for pursuing vocational training in Germany. The Ausbildung system frames the entire program in a way, so that the students not only remain involved in education but also become acquainted with the workforce and society. Applicants for vocational training are often required to undergo language proficiency tests.
                                                    <br /><br />
                                                    <b>Proficiency level:</b> The proficiency level in German and English depends on the complexity of the training and the area of work. Hence, the language proficiency level can be between B1 and C1 levels. Language efficiency and fluency higher than C1 Level may also be required in professions like technical and healthcare.
                                                    <br /><br />
                                                    <b>Language tests:</b> Most educational institutions, Universities, Employers, and Vocational training providers mainly accept language certification from widely recognised bodies like TestDaF, DSH, and Goethe-Zertifikat. Indian students must learn German and pass the German language test from Goethe Institute, as it's the only institute from India acknowledged in Germany.
                                                </p>
                                            </section>

                                            <section className="eligibility">
                                                <h4 className='m-0'>Eligibility and Requirements for Admission</h4>
                                                <p>
                                                    Vocational training in Germany is a lucrative opportunity for Indian students as it helps in successful career development. Indian students can gain hands-on experience and theoretical knowledge in their desired field. However, to get this opportunity, it is mandatory to fulfil all the eligibility criteria for visa and immigration, admission to vocational training, and accommodation in Germany.
                                                </p>
                                                <br />
                                                <b>1. Minimum Age Criteria:</b> The minimum age for vocational courses is 15. However, because of legal contracts and requirements, the minimum age for international students and learners is 18. This adjustment is important because in most countries, the legal age is 18, and many documents, such as rentals, insurance, accommodation, etc., require a minimum age of 18. The upper limit, in some cases, is 35.
                                                <br /><br />
                                                <b>2. Education qualification:</b> Students must have completed their Higher Secondary Education or Senior Secondary, ITI or Senior College Diploma or a Technical College Diploma.
                                                <br /><br />
                                                <b> 3. German Language Certificate:</b> A minimum of B1-level proficiency in German is required. Some organisations even ask for C1-level proficiency. Certain institutions teach the German language, and Germany accepts only certificates from these institutes.
                                                Goethe Institute is the only institute from India acknowledged in Germany.
                                                <br /><br />
                                                <b>4. Other requirements: </b>

                                                <ol>
                                                    <li>   In some institutions, master craftsman certificates or state-certified technician experience are required. Alternatively, someone who has completed two years of vocational training and three years of professional experience can also qualify. </li>

                                                    <li>In most cases, Mittlere Reife (9th Class, intermediate school-leaving certificate), Realabschluss (substantive general certificate of secondary education), and/or Abitur (A-Level, 12 years, with university entrance qualification) are also required. </li>

                                                    <li>Blocked Account – International students, including Indian students who are opting for vocational training in Germany, generally need to provide proof of financial resources to obtain a visa or residence permit. A blocked account ("Sperrkonto") is one standard method to prove students’ financial capability. If the training takes place entirely at school facilities and the salary is below €903, or if the training occurs at a company (in-firm) facilities along with a vocational school (“Berufsschule”) and the salary is below €927, the financial gap must be covered by a blocked account or a "Verpflichtungserklärung" from a sponsor in Germany.</li>
                                                    <li>Secure funding – Students must show parents’ income proof, sufficient to bear the student’s overall cost. Scholarship is also applicable, which covers the student’s study and living. A declaration of Commitment (Verpflichtungserklärung) to a foreign authority in Germany is also valid proof. </li>

                                                    <li>Numerus Clausus – Most universities and training programs might ask for Numerus Clausus or “NC” during the admission. </li>
                                                    <li>Passport copy         </li>
                                                    <li>Ausbildung offer letter from a company in Germany  </li>
                                                    <li>A CV highlighting your qualifications, </li>
                                                    <li>Statement of Purpose </li>
                                                    <li>Letter of Recommendation       </li>
                                                    <li>All educational certificates        </li>
                                                    <li>Declaration of commitment or in-company training allowance </li>
                                                    <li>Work experience or Internship certificates (if any)   </li>
                                                    <li>Embassy fee paid slip</li>
                                                </ol>
                                            </section>

                                            <section className="visa-info">
                                                <h4 className='m-0'>Visa Application and Documents</h4>
                                                <p>Students must have found their vocational training location at German companies. The Federal Employment Agency (BA) must approve your vocational training place, whether dual or in-company. It’s recommended that students start the German Student Visa application process and set up a visa interview at the German Embassy or Consulate in their home country before admission. The student must present all documents and valid proofs during the visa interview.  <br /><br />

                                                    The following are the visa documents mainly required for Indian students while applying for a Bachelor’s program in Germany: </p>
                                                <ul>
                                                    <li>Duly filled and signed national visa application form.</li>
                                                    <li>Two photocopies of passport.</li>
                                                    <li>Birth certificate, </li>
                                                    <li>Valid passport. </li>
                                                    <li>Marriage certificate (if applicable). </li>
                                                    <li>Your child’s birth certificate (if applicable). </li>
                                                    <li>Recent passport-style photographs (up to 3). </li>
                                                    <li>Blocked Account</li>
                                                    <li>Photocopies of previous German residence titles (if applicable)</li>
                                                </ul>
                                                <br />
                                                <b>Types of Visas Availability for Vocational Training </b>
                                                <p>There are mainly two types of Visas available for people seeking vocational training (Ausbildung) in Germany. These are: </p>
                                                <br />
                                                <b>1. Section 16A AufenthG – Resident Permit for Vocational Training:</b> People looking for either school-based or company-based vocational training are eligible to attain this Visa. It is issued based on a training program duration that generally lasts two years. This visa also permits an allowance to take up a second job while having vocational training, provided the person can attain a job of 20 hours per week. <br />
                                                <b>2. Section 17 (1) AufenthG—Visa for Seeking a Vocational Training Place:</b> This visa is for people who have yet to secure their desired vocational training. Learners can get a permit to enter Germany, stay up to 9 months and find a suitable residence. This permit allows you to work up to 20 hours a week by completing up to two weeks of trial work.
                                            </section>
                                            <br/>
                                            <section className="financial-aid">
                                                <h4 className='m-0'>Options for Financial Aids</h4>
                                                <p>Scholarship options and other financial aids like part-time work permits and student loans are available to secure students seeking vocational training in Germany.</p>
                                                Some ways a student’s funding can be secure are:
                                                <ul>
                                                    <li>Apprenticeship Allowance</li>
                                                    <li>BAföG</li>
                                                    <li>Student loans</li>
                                                    <li>Part-time work</li>
                                                    <li>private loans and Bildungskredit</li>
                                                    <li>DAAD scholarships</li>
                                                </ul>
                                                Understand these to avoid financial stress and focus on vocational training in Germany.
                                            </section>
                                            <br/>
                                            <section className="faqs">
                                                <h4 className='m-0'>FAQs</h4>

                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(1)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 1 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;How many years does the vocational training last?
                                                    </h6>
                                                    {openFAQ === 1 && (
                                                        <p>The duration of vocational training depends upon the type you have chosen. Accelerated programs mainly offer shorter training programs that can be completed in less than 2 years. School-based vocational training can range from 1 to 3 years, whereas dual vocational degree programs last for 3 to 5 years. </p>
                                                    )}
                                                </div>

                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(2)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 2 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;Can I pursue vocational training without learning the German language?
                                                    </h6>
                                                    {openFAQ === 2 && (
                                                        <p>German language is a necessary criterion for pursuing vocational training. Indian students must learn German from the Goethe Institute, which is recognised in Germany. International students must show proof of German Language Proficiency. The B1 or C1 level of proficiency is mainly accepted. </p>
                                                    )}
                                                </div>

                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(3)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 3 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;How much German language proficiency is required?
                                                    </h6>
                                                    {openFAQ === 3 && (
                                                        <p>Depending on the job requirements, some companies require a B1 level, and some require a C1 level or above. </p>
                                                    )}
                                                </div>
                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(4)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 4 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;Can I work part-time in Germany during the Ausbildung?
                                                    </h6>
                                                    {openFAQ === 4 && (
                                                        <p>Yes, a learner enrolled in Vocational training can work part-time under certain regulations.  </p>
                                                    )}
                                                </div>
                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(5)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 5 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;Do I need a blocked account for Ausbildung in Germany?
                                                    </h6>
                                                    {openFAQ === 5 && (
                                                        <p>International students, including Indian students who are opting for vocational training in Germany, generally need to provide proof of financial resources to obtain a visa or residence permit. A blocked account ("Sperrkonto") is one standard method to prove students’ financial capability. If the training takes place entirely at school facilities and the salary is below €903, or if the training occurs at a company (in-firm) facilities along with a vocational school (“Berufsschule”) and the salary is below €927, the financial gap must be covered by a blocked account or a "Verpflichtungserklärung" from a sponsor in Germany.</p>
                                                    )}
                                                </div>
                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(6)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 6 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;Do I have the option of extending my stay in Germany after I have completed my Ausbildung?
                                                    </h6>
                                                    {openFAQ === 6 && (
                                                        <p>After completing the training, the visa can be extended if a student gets a job offer from the same company. </p>
                                                    )}
                                                </div>
                                                <div className="faq-item">
                                                    <h6 onClick={() => toggleFAQ(7)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {openFAQ === 7 ? <FaChevronDown /> : <FaChevronRight />}
                                                        &nbsp;Can I opt for permanent residency?
                                                    </h6>
                                                    {openFAQ === 7 && (
                                                        <p>Yes, if you have worked for over two years in Germany after completing vocational training, you can apply for permanent residency. However, additional criteria need to be fulfilled, such as proficiency in the German language and fluency, sufficient economic strength, etc. </p>
                                                    )}
                                                </div>
                                            </section>

                                            {/* <footer>
                                                    <p>For more information, visit the official vocational training websites.</p>
                                                </footer> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
