const subTitle = "";
const title = "Why Students Choose ASPIRATIONS INC";
const desc = "Our team of expert education consultants focus on Germany, and has answer to every know-hows of Germany and their academic excellence! Our consultants have high industry experience and are well-versed with Germany and their education system and culture, and are constantly updated with latest news and any changes in the policy. We thoroughly guide each and every student through application process, Visa and Immigration support, admission and post admission guidance. Also we can guide you with everything you need to know in order to stay, study, and achieve success in Germany ";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: '"High success rate"  ',
        desc: 'We can proudly say, we have 100% success rate in placing our students in their desired programmes under the best universities in Germany. Students undergo a thorough evaluation from ASPIRATIONS INC, due to which they secure admissions in the best institutions in Germany',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: '"Our services are comprehensive"',
        desc: 'Our support encompasses an all-inclusive guidance which includes Education consultation and evaluation process, application assistance, Visa and Immigration support, pre-departure orientation, post admission support. We also ensure the student has all the pre-requisite requirements in order to get admission, scholarship, accommodation and make sure the student holds proper knowledge on language, culture, laws and social structure of Germany',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: '"Problem solving approach"',
        desc: 'We solve queries of students that mostly come across their minds or are feared of. For instance “how to study in Germany?”, “cost of studying in Germany?”, “is Germany safe for International students?”, “are there free of cost study opportunities in Germany”?” “UG courses, and PG courses in Germany?” “can I avail PhD in Germany?”, “after study job assistance in Germany” and much more. We not only solve their queries, but we also solve their doubts and confusion and build in confidence to excel in German universities'
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: '"Personalised approach"',
        desc: 'Each student is different and unique. Each student has got his/her/their own preferences of studies and choice of universities. We understand each student’s personal requirements and evaluate the eligibility criteria. We check whether the requirements of the student align with that of the "education system of Germany" and befits the criteria of the universities. We also help the student groom up and build confidence (if required) Eventually, we proceed to assist in assessing the budget, application process, Visa and immigration support, insurance and other essential criteria that are required for an Indian student to study in Germany',
    },
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: '"Clear fee structure"  ',
        desc: 'We take standard fees for assisting our fellow Indian students in "getting secured admissions in the best universities and institutions of Germany". We keep transparency in our fees and in breakdown of the fees structure. We do not have any hidden charges and highly discourage any sort of fraudulent activities and transactions from both ends',
    },
    // {
    //     imgUrl: 'assets/images/about/icon/01.jpg',
    //     imgAlt: 'about icon rajibraj91 rajibraj',
    //     title: 'Free Demo Consultation  ',
    //     desc: 'Students can book us for a free demo consultation, where we provide basic information and guidance to students regarding the process and persuasion of education in Germany. One van visit our contact page for a free of cost demo consultation',
    // },
]


const About = () => {
    return (
        <>
            <div className="about-sections" style={{ overflow: 'hidden' }}>
                <div className="container">
                    <div className="section-header text-center mb-0" >
                        <h2 className="title">{title}</h2>
                        {/* <hr /> */}
                        <h5>“Exceptional knowledge on Germany and it’s Education system”</h5>
                        <p>{desc}</p>
                    </div>
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse m-0">
                        <div className="col mb-0">
                            <div className="about-right mb-0">
                                <div className="section-wrapper" style={{ overflow: 'hidden' }}>
                                    <ul className="lab-ul">
                                        {aboutList.slice(0, 4).map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    {/* <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /> */}
                                                </div>
                                                <div className="sr-right">
                                                    <h5 style={{ margin: 0 }}>{val.title}</h5>
                                                    <p >{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="section-header">
                                <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span>
                            </div>
                            <div className="about-left mb-5">
                                <div className="about-thumb" >
                                    <img src="assets/images/about/01.png" alt="about" />
                                </div>
                            </div>
                            <div className="about-left m-0">
                                <div className="section-wrapper" style={{ overflow: 'hidden' }}>
                                    <ul className="lab-ul">
                                        {aboutList.slice(4, 6).map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    {/* <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /> */}
                                                </div>
                                                <div className="sr-right">
                                                    <h5 style={{ margin: 0 }}>{val.title}</h5>
                                                    <p >{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default About;