import { Component, Fragment, useEffect, useRef, useState } from "react";
import GoogleMap from "../sidebar/googlemap";
import { Toast } from "@chakra-ui/react";

import axios from "axios";
import * as mod from "./../../url.js"
const title = <h2 className="title">Need Any <span>Help?</span></h2>;
const desc = "You can contact with us for anykinds of informations and help. We are ready to help you.";


const statess = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"]; // Example states
const Courses = ['MBA', 'PHD', 'B.Tech']; // Example courses
const citys = ["Mumbai",
    "Delhi",
    "Kolkata",
    "Chennai",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Chandigarh",
    "Bhopal",
    "Surat",
    "Vadodara",
    "Indore",
    "Nagpur",
    "Patna",
    "Visakhapatnam",
    "Gurgaon",
    "Noida",
    "Agra",
    "Varanasi",
    "Madurai",
    "Rajkot",
    "Coimbatore",
    "Kochi",
    "Mysuru",
    "Kozhikode",
    "Chandrapur",
    "Udaipur",
    "Vijayawada",
    "Faridabad",
    "Nashik",
    "Bhubaneswar",
    "Jodhpur",
    "Thane",
    "Gwalior",
    "Jamshedpur",
    "Bhubaneshwar",
    "Dhanbad",
    "Amritsar",
    "Ludhiana",
    "Meerut",
    "Navi Mumbai",
    "Madurai",
    "Siliguri",
    "Ranchi",
    "Shimla",
    "Dehradun",
    "Pondicherry"]

// Example languages
const contactInfoList = [
    {
        imgUrl: 'assets/images/contact/icon/01.png',
        imgAlt: 'contactinfo rajibraj91 rajibraj',
        subTitle: 'Make a Call:',
        title: '+91 93100 72343',
    },
    {
        imgUrl: 'assets/images/contact/icon/02.png',
        imgAlt: 'contactinfo rajibraj91 rajibraj',
        subTitle: 'Send Us Messege at:',
        title: 'info.education@aspirations.global',
    },
]

const subTitle = "";
// const title = "We're Always Eager To Hear From You!";
const conSubTitle = " Contact us";
const conTitle = "  ";
const btnText = "Send our Message";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Meet Us',
        desc: '7-8, Ground Floor Vijaya Building,; 17 - Barakhamba Road;  New Delhi- 110001',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: '',
        desc: '+91 93100 72343',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: '',
        desc: 'info.education@aspirations.global',
    },

]
const ContactInfo = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [mobile, setMobile] = useState('');
    const [state, setState] = useState('');
    const [course, setCourse] = useState('');
    const [city, setCity] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const form = useRef();

    const SubmitHandler = async (e) => {
        e.preventDefault(); // Prevent form from refreshing the page on submit
        if (!name || !mobile || !email || !message) {
            Toast({
                title: "Please fill all the blanks.",
                status: "warning",
                duration: 500,
                isClosable: true,
                position: "top-right",
            });
            return;
        }

        try {
            setButtonDisabled(true); // Disable button
            startCountdown(); // Start countdown

            const response = await axios.post(`${mod.api_url}/api/v1/contact/submit_details`, {
                name,
                mobile,
                email,
                message,
                state,
                course,
                city,
            });

            setName("");
            setMobile("");
            setEmail("");
            setMessage("");

            Toast({
                title: "Your query has been sent successfully.",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });

        } catch (error) {
            Toast({
                title: "Failed to send your data.",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    const startCountdown = () => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                    clearInterval(intervalId);
                    setButtonDisabled(false); // Re-enable button after countdown
                    setCountdown(10); // Reset countdown
                }
                return prevCountdown - 1;
            });
        }, 1000); // 1-second interval
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (<>

        <div className="contact-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{ color: 'gray' }}>{conSubTitle}</span>
                    <h2 className="title">{conTitle}</h2>
                </div>
                <div className="section-wrapper">
                    <form className="contact-form" ref={form}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name *"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email *"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="mobile"
                                placeholder="Mobile Number *"
                                onChange={(e) => setMobile(e.target.value)}
                                value={mobile}
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                name="user_course"
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                                required
                                placeholder="Your Course *"
                            >
                                {/* <option value="" disabled>Select Course *</option>
                                {Courses.map((course) => (
                                    <option key={course} value={course}>{course}</option>
                                ))} */}
                            </input>
                        </div>
                        <div className="form-group">
                            <select
                                name="user_state"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select Your State *</option>
                                {statess?.map((state) => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select
                                name="user_city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select  Nearest city *</option>
                                {citys.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group w-100">
                            <textarea
                                rows="4"
                                type="text"
                                name="message"
                                placeholder="Your Message"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                required >
                            </textarea>
                        </div>
                        <div className=" row align-items-center">
                            <div className="col-auto">
                                <input
                                    type="checkbox"
                                    checked={isAgreed}
                                    onChange={(e) => setIsAgreed(e.target.checked)}
                                    className=""
                                    required
                                />
                            </div>
                            <div className="col m-2">
                                <span className="agreement-text"> I Agree to receive information and calls from Aspirations Inc.</span>
                            </div>
                        </div>
                        <div className="form-group m-5 w-100 text-center">
                            <button
                                type="submit"
                                disabled={isButtonDisabled}
                                className="btn btn-primary"
                                onClick={SubmitHandler}
                            >
                                {isButtonDisabled
                                    ? `Sending... (${countdown})`
                                    : "Send Email"}
                            </button>
                        </div>
                    </form>

                    {/* <div className="row flex-row-reverse">
                        
                        <div className="col-xl-8 col-lg-7 col-12">
                        
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="map-address-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row flex-row-reverse">
                        <div className="col-xl-4 col-lg-5 col-12">
                            <div className="contact-wrapper">
                                {contactList.map((val, i) => (
                                    <div className="contact-item" key={i}>
                                        <div className="contact-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="contact-content">
                                            <h6 className="title">{val.title}</h6>
                                            <p>{val.desc.split(';').map((line, index) => (
                                                <p key={index}>{line.trim()}</p>
                                            ))}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-12">
                            <GoogleMap />
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>);
}


export default ContactInfo;



