import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url"
import { Image, useToast } from "@chakra-ui/react";
import axios from "axios";
import { Button } from "bootstrap";

const title = "Most Popular Post";



const PopularPost = () => {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const fetchBlogs = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${mod.api_url}/api/v1/blogs/get_all`, {
                params: { page, limit: 6 }, // Assuming 9 items per page
            });
            setBlogs(response?.data?.blogs || []);
            setTotalPages(response?.data?.totalPages || 1);
            setCurrentPage(page);
        } catch (error) {
            console.error("Error fetching blogs:", error);
            toast({
                title: "Error fetching blogs.",
                description: error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    return (
        <div className="widget widget-post">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <ul className="widget-wrapper">
                {blogs?.map((val, i) => (
                    <li className="d-flex flex-wrap justify-content-between" key={i}>
                        <div className="post-thumb">
                            <Link to={`/blog-single/${val.slug}`}>
                                <img src={
                                    `${mod.api_url}/api/v1/blogs/singlePhoto/${val._id}` ||
                                    "placeholder.jpg"
                                } />
                            </Link>                        </div>
                        <div className="post-content">
                            <Link to={`/blog-single/${val.slug}`}>
                                <h6>{val.heading.substring(0, 50)}</h6>
                            </Link>                            <p>{val.date}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PopularPost;