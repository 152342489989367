
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import Privacypolicy from "./page/privacy-policy";
import BlogSingle from "./page/blog-single";
import ContactPage from "./page/contact";
import CourseSingle from "./page/services";
import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import InstructorPage from "./page/instructor";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";
import Blog from "./page/blog";
import { PHD_Degree_In_Germany } from "./page/program/PHD_Degree_In_Germany";
import { Finance_In_Germany } from "./page/program/Finance _In_Germany";
import { German_Language_Classes } from "./page/program/German_Language_Classes";
import { Bachelors_In_Germany } from "./page/program/Bachelor's_In_Germany";
import { Vocational_In_Germany } from "./page/program/Vocational_In_Germany";
import { Application_assistance } from "./page/services/Application_assistance";
import { Personalised_Consultation } from "./page/services/Personalised_Consultation";
import { Pre_Departure_Orientation } from "./page/services/Pre_Departure_Orientation";
import { Visa_and_Immigration_Support } from "./page/services/Visa_and_Immigration_Support";
import RegistrationForm from "./page/registerationForm";
import { APS_verification } from "./page/services/APS_verification";



function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="services" element={<CourseSingle />} />
				<Route path="blog" element={<Blog />} />
				<Route path="blog-single/:slug" element={<BlogSingle />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="privacy-policy" element={<Privacypolicy />} /> 
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="instructor" element={<InstructorPage />} />
				<Route path="search-page" element={<SearchPage />} />
				<Route path="contact_details" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="admin_login_page" element={<LoginPage />} />
				<Route path="signup" element={<SignupPage />} />
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="register" element={<RegistrationForm />} />
				<Route path="*" element={<ErrorPage />} />
				{/* programlinks */}
				<Route path="phd-degree-in-germany" element={<PHD_Degree_In_Germany />} />
				<Route path="finance-in-germany" element={<Finance_In_Germany />} />
				<Route path="masters-degree-in-germany" element={<German_Language_Classes />} />
				<Route path="bachelors-in-germany" element={<Bachelors_In_Germany />} />
				<Route path="vocation-in-germany" element={<Vocational_In_Germany />} />
				{/* services */}
				<Route path="aplication-assistance" element={<Application_assistance />} />
				<Route path="Germany-Job-Seeker-Visa" element={<Personalised_Consultation />} />
				<Route path="APS-verification" element={<APS_verification />} />
				<Route path="Opportunity-Card-Chancenkarte" element={<Pre_Departure_Orientation />} />
				<Route path="visa-and-immigration-support" element={<Visa_and_Immigration_Support />} />
				{/* <Route path="" element={<ee/>}/> */}
			</Routes>
		</BrowserRouter >
	);
}

export default App;
