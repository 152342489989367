

const subTitle = "";
const title = "Students can avail Scholarship opportunities";
const desc = "There are plenty of scholarships that are available from private organisations and DAAD, which are designed mainly for International students. Hence Indian students can also apply for scholarship to ease down their finances ";


const eventListOne = [
    {
        imgUrl: 'assets/images/event/01.jpg',
        imgAlt: 'event rajibraj91 rajibraj',
        title: 'Rising Indian community',
        Desce: ' Due to the welcoming ambiance of Germany, the Indian community is getting bigger just like other international communities residing in Germany. This can give Indian students a strong support and sense of security and ‘home away from home’ feeling ! The academic excellence combined with the ease of living a high quality life and availing practical amenities, makes Germany an ideal place to pursue academic programmes and degrees ',
    },
]

const eventListTwo = [
    {
        title: 'PhD and Research',
        Desce: ' Students can also get a chance to study highest form of education in Germany. Indian students can pursue PhD and/research from German universities and contribute on latest innovation and advancements ',
    },
    {
        title: 'Employment assistance',
        Desce: 'International students are allowed to work for a certain number of hours per week while studying in German university. They are also allowed to work during semester breaks and earn experience.',
    },
    {
        title: 'Post-study employment',
        Desce: 'After the academic programme is completed, International students can get post-study employment opportunities and assistance. One can apply for Visa extension program and seek for better job opportunities.',
    },
    {
        title: 'Network of industries',
        Desce: 'Germany being one of the most powerful countries in Europe as well as globally, it has strong industry links all over Europe and the world. Germany strong relationships with major corporations and companies provide graduates, wealth of opportunities for internships, research positions, and employment or they may also choose to study further',
    },
    {
        title: 'High living standards ',
        Desce: 'The lifestyle of Germany has always known to be quite high and amicable. It offers mesmerizing scenic beauty as well as lots of historic and modern infrastructure. Social security, healthcare, culture and cuisines are at the finest! Indian students can live safely and hassle free in Germany to pursue any type of education and academic programmes in best German institutes and universities ',
    },
    // {
    //     title: 'Rising Indian community',
    //     Desce: 'Due to the welcoming ambiance of Germany, the Indian community is getting bigger just like other international communities residing in Germany. This can give Indian students a strong support and sense of security and ‘home away from home’ feeling The academic excellence combined with the ease of living a high quality life and availing practical amenities, makes Germany an ideal place to pursue academic programmes and degrees ',
    // },

]



const Event = () => {
    return (
        <div className="event-section " style={{ overflow: 'hidden' }}>
            <div className="container">
                {/* <div className="section-header text-center" data-aos="fade-right" data-aos-duration="1000">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                    <hr />
                    <p>Study in Germany consultants assist you in selecting the finest courses for studying in Germany <br /> and guide effective preparations and arrangements.</p>
                </div> */}
                <div className="section-wrapper" style={{ overflowX: 'hidden' }}>
                    <div className="row row-cols-lg-2 row-cols-1 g-4">
                        {eventListOne.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="event-left">
                                    <div className="event-item">
                                        <div className="event-inner">
                                            <div className="event-thumb" data-aos="fade-left" data-aos-duration="1000">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="event-content">
                                                <div className="event-content-info">
                                                    <a href="#"><h5 data-aos="fade-right" data-aos-duration="1000">{val.title}</h5></a>
                                                    <ul className="lab-ul" data-aos="fade-left" data-aos-duration="1000">
                                                        <li>{val.Time}</li>
                                                        <li>Due to the welcoming ambiance of Germany, the Indian community is getting bigger just like other international communities residing in Germany. This can give Indian students a strong support and sense of security and ‘home away from home’ feeling!
                                                            <br /> The academic excellence combined with the ease of living a high quality life and availing practical amenities, makes Germany an ideal place to pursue academic programmes and degrees</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="col">
                            <div className="event-right">
                                {eventListTwo.map((val, i) => (
                                    <div className="event-item" key={i}>
                                        <div className="event-inner">
                                            <div className="event-content">
                                                <div className="event-content-info">
                                                    <a href="#"><h5 data-aos="fade-left" data-aos-duration="1000" style={{ margin: 0 }}>{val.title}</h5></a>
                                                    <ul className="lab-ul" data-aos="fade-right" data-aos-duration="1000">
                                                        <li> {val.Desce}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Event;