import React from "react";
import Header from "../component/layout/header";
import Footer from "../component/layout/footer";
import PageHeader from "../component/layout/pageheader";



export const Privacypolicy = () => {
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    {/*<div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/admission-assistance.jpg" alt="admission-assistance" className="w-100" />
                                <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> 
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">

                                            <section className="benefits">
                                                <h2>Privacy Policy for Aspirations Inc </h2>
                                                <h4>1. Introduction</h4>
                                                <p>Aspirations Inc. is an entity registered in New Delhi, India. Our comprehensive suite of services caters to a diverse clientele, encompassing students, professionals, universities, and corporations. We are dedicated to maintaining the privacy of our clients, visitors, and other users of the website, https://aspirations.global, its subdomains, web applications, and mobile applications.</p>
                                                <p>The purpose of this Privacy Policy is to explain how we handle your information and demonstrate our commitment to privacy laws. In this document, "Aspirations Inc.," "we," or "us" refers to Aspirations Inc. and its affiliates, and "you" refers to any user who has provided information, including Personal Information, and is using any features of our services.</p>  
                                                <h4>2. Information We Collect</h4>
                                                <p>We may collect the following types of information:</p>
                                                <ul>
                                                    <li><b>Personal Information:</b> This may include your name, email address, postal address, phone number, date of birth, nationality, educational background, language proficiency, financial information (if required for specific services), and any other information you provide to us directly.</li>
                                                    <li><b>Application Information:</b> Information related to your university applications, such as chosen programs, transcripts, letters of recommendation, CV/resume, and other application materials.</li>
                                                    <li><b>Website Usage Information:</b> We may collect information about your interactions with our website, such as pages visited, time spent on pages, links clicked, IP address, browser type, device information, and referring website. We may use cookies, web beacons, and other tracking technologies to collect this information.</li>
                                                    <li><b>Communication Information:</b> Records of your communications with us, including emails, chat logs, and phone calls.</li>
                                                </ul>
                                                <h4>3. How We Use Your Information</h4>
                                                <p>We may use your information for the following purposes:</p>
                                                <ul>
                                                    <li><b>Providing Services:</b> To provide you with the Services you requested, such as assisting with university applications, providing information about German universities, and offering personalized guidance.</li>
                                                    <li><b>Communication:</b> To communicate with you about your applications, our Services, updates, and promotional materials (you can opt-out of marketing communications at any time).</li>
                                                    <li><b>Improving Services:</b> To analyze website usage and improve our website and Services.</li>
                                                    <li><b>Legal Compliance:</b> To comply with applicable laws and regulations.</li>
                                                    <li><b>Personalization:</b> To personalize your experience on our website and tailor our Services to your needs.</li>
                                                    <li><b>Application Processing:</b> To process your university applications, including sharing your information with universities in Germany (with your explicit consent).</li>
                                                </ul>
                                                <h4>4. Sharing Your Information</h4>
                                                <p>We may share your information with the following categories of third parties:</p>
                                                <ul>
                                                    <li><b>German Universities:</b> With your explicit consent, we will share your application information with the universities you apply to.</li>
                                                    <li><b>Service Providers:</b> We may share your information with third-party service providers who assist us with website hosting, data analysis, email delivery, and other services. These providers are contractually obligated to protect your information.</li>
                                                    <li><b>Legal Authorities:</b> We may disclose your information to legal authorities if required by law or legal process.</li>
                                                    <li><b>Business Transfers:</b> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                                                </ul>
                                                <h4>5. Data Security</h4>
                                                <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no data transmission over the internet is completely secure.</p>
                                                <h4>6. Data Retention</h4>
                                                <p>We will retain your information for as long as necessary to provide you with the Services and fulfil the purposes described in this Privacy Policy. We may also retain your information for a longer period if required by law.</p>
                                                <h4>7. Your Rights</h4>
                                                <p>You may have certain rights regarding your personal information, including the right to access, correct, or delete your information. You may also have the right to object to or restrict the processing of your information. If you wish to exercise these rights, please contact us.</p>
                                                <h4>8. Children's Privacy</h4>
                                                <p>Our website and Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
                                                <h4>9. Changes to this Privacy Policy</h4>
                                                <p>We may update this Privacy Policy from time to time. We will post any changes on our website and notify you as required by law.</p>
                                                <h4>10. Contact Us</h4>
                                                <p>If you have any questions about this Privacy Policy, please contact us at:<br/><b>grievance@aspirations.global</b></p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Privacypolicy;