import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const phoneNumber = "+919310072343";
const address = "7-8, Ground Floor Vijaya Building, 17 - Barakhamba Road  New Delhi- 110001";
const locationUrl = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7004.049287167359!2d77.2245489!3d28.6290235!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd33dbb1e293%3A0x14124d0995cfc072!2sVijaya%20Building!5e0!3m2!1sen!2sin!4v1716706790842!5m2!1sen!2sin";
const openWhatsApp = () => {
    const phoneNumber = "+91 93100 72343"; // Replace with the recipient's phone number
    const message = "Hello, I want to chat with you!"; // Custom message
    // const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
};
let socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/profile.php?id=61555917730119',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/aspirationsincglobal/',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/aspirations-inc-global/',
    }
];

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [socialToggle, setSocialToggle] = useState(false);
    const [headerFixed, setHeaderFixed] = useState(false);
    const [subMenuToggle, setSubMenuToggle] = useState(false);
    window.addEventListener("scroll", () => {
        if (window.scrollY > 200) {
            setHeaderFixed(true);
        } else {
            setHeaderFixed(false);
        }
    });

    return (
        <header className={`header-section ${headerFixed ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li onClick={openWhatsApp}><i className="icofont-whatsapp" style={{ color: 'green' }}></i> <span>{phoneNumber}</span></li>
                            <li><a href={locationUrl} target="_blank" rel="noopener noreferrer"><i className="icofont-location-pin" style={{ color: "blue" }}></i> {address} </a></li>
                        </ul>
                        <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink} target="blank"><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/"><img src="assets/images/logo/01.png" alt="logo" /></Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li className="menu-item">
                                        <NavLink to="/">Home</NavLink>
                                    </li>

                                    <li className="menu-item">
                                        <Menu>
                                            {/* MenuButton with no background */}
                                            <MenuButton
                                                background="none"
                                                fontWeight="bold"
                                                padding="10px 15px"
                                                margin="5px"
                                                lineHeight="1.5"
                                                onMouseEnter={() => setMenuToggle(true)}
                                                onMouseLeave={() => setMenuToggle(false)}
                                            >
                                                Services <ChevronDownIcon />
                                            </MenuButton>

                                            {/* MenuList with background color */}
                                            <MenuList
                                                display={menuToggle ? "block" : "none"}
                                                background="tomato" borderRadius="md" padding="10px"
                                                onMouseEnter={() => setMenuToggle(true)} // Keep menu open on hover
                                                onMouseLeave={() => setMenuToggle(false)} // Close when not hovering
                                            >
                                                <MenuItem
                                                    as={Link}
                                                    to="/aplication-assistance"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    marginBottom="2"
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    Application Assistance
                                                </MenuItem>
                                                <MenuItem
                                                    as={Link}
                                                    to="/APS-verification"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    marginBottom="2"
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    APS Verification
                                                </MenuItem>

                                                <Menu>
                                                    {/* MenuItem for Visa and Immigration Support */}
                                                    <MenuItem
                                                        as={Link}
                                                        to="/visa-and-immigration-support"
                                                        background=""
                                                        _hover={{ background: "tomato" }}
                                                        marginBottom="2"
                                                        borderRadius="md"
                                                        p={3}
                                                        onMouseEnter={() => setSubMenuToggle(true)} // Show nested menu on hover
                                                        onMouseLeave={() => setSubMenuToggle(false)} // Hide nested menu when mouse leaves
                                                    >
                                                        Visa and Immigration Support
                                                        {/* Chevron icon to indicate dropdown */}
                                                        <ChevronDownIcon ml={2} />

                                                        {/* Nested Menu (Sub Menu) */}
                                                        <Menu
                                                            isOpen={subMenuToggle} // Open the nested menu if subMenuToggle is true
                                                            placement="right-start" // Place the dropdown to the right of the parent menu item
                                                        >
                                                            <MenuList margin='160' background="tomato" borderRadius="md" padding="10px">
                                                                <MenuItem
                                                                    as={Link}
                                                                    to="/Germany-Job-Seeker-Visa"
                                                                    background=""
                                                                    _hover={{ background: "tomato" }}
                                                                    marginBottom="2"
                                                                    borderRadius="md"
                                                                    p={3}
                                                                >
                                                                    Germany Job Seeker Visa
                                                                </MenuItem>
                                                                <MenuItem
                                                                    as={Link}
                                                                    to="/Opportunity-Card-Chancenkarte"
                                                                    fontSize="md"
                                                                    background=""
                                                                    _hover={{ background: "tomato" }}
                                                                    marginBottom="2"
                                                                    borderRadius="md"
                                                                    p={3}
                                                                >
                                                                    Opportunity Card/Chancenkarte
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </MenuItem>
                                                </Menu>
                                            </MenuList>
                                        </Menu>
                                    </li>
                                    <li className="menu-item">
                                        <Menu>
                                            {/* MenuButton */}
                                            <MenuButton
                                                background="none"
                                                // _hover={{ color: "blue" }} // Prevent hover background
                                                // _active={{ bg: "none" }}
                                                fontWeight="bold"
                                                padding="10px 15px" // Add padding
                                                margin=" 5px" // Add margin
                                                lineHeight="1.5" // Set line height
                                                onMouseEnter={() => setMenuToggle(true)}
                                            >
                                                Programs <ChevronDownIcon />
                                            </MenuButton>

                                            {/* MenuList with background color */}
                                            <MenuList background="tomato" borderRadius="md" padding="10px">
                                                {/* Dropdown Items with spacing and background color */}
                                                <MenuItem
                                                    as={Link}
                                                    to="/bachelors-in-germany"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    marginBottom="2"
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    Bachelor's In Germany
                                                </MenuItem>

                                                <MenuItem
                                                    as={Link}
                                                    to="/masters-degree-in-germany"
                                                    fontSize="md"
                                                    background=""
                                                    mb={2}
                                                    _hover={{ background: "tomato" }}
                                                    p={3}
                                                >
                                                    Master’s Degree In Germany
                                                </MenuItem>

                                                <MenuItem
                                                    as={Link}
                                                    to="/vocation-in-germany"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    marginBottom="2"
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    Nursing/Vocational In Germany
                                                </MenuItem>

                                                {/* <MenuItem
                                                    as={Link}
                                                    to="/finance-in-germany"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    marginBottom="2"
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    Finance In Germany
                                                </MenuItem> */}

                                                <MenuItem
                                                    as={Link}
                                                    to="/phd-degree-in-germany"
                                                    background=""
                                                    _hover={{ background: "tomato" }}
                                                    borderRadius="md"
                                                    p={3}
                                                >
                                                    PHD Degree In Germany
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </li>


                                    <li className="menu-item">
                                        <NavLink to="/blog">Blog</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/about">About Us</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/contact">Contact Us</NavLink>
                                    </li>

                                </ul>
                            </div>
                            <div
                                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                                onClick={() => setMenuToggle(!menuToggle)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square" style={{ color: 'gray' }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;