import React, { useState } from 'react'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'; // Using Font Awesome for icons

const title = "MASTER’S DEGREE IN GERMANY";
const desc = "Master’s programs in Germany offer a wide range of subjects, providing diverse opportunities for your academic and professional growth. This diverse range of options also includes some master’s programs taught in English. After completing your bachelor's program, you can apply for a master's program in Germany by following some crucial steps. These steps include meeting all the criteria for moving to Germany, gaining admission to German universities and academic institutions, and finding suitable accommodation in Germany.";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const German_Language_Classes = () => {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const toggleFAQ1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleFAQ2 = () => {
        setIsOpen2(!isOpen2);
    };
    const [openFAQ, setOpenFAQ] = useState(null); // Track which FAQ is open

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ by index
    };
    return (
        <><Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/masters-in-germoney.jpg" alt="masters-in-germoney" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <div className="masters-page">
                                                <header className="masters-header">
                                                    {/* <h1>Master's Degree in Germany</h1> */}
                                                    <p>
                                                        The academic data states that during the years 2022-2023, around 42,578 Indian students were enrolled in Germany pursuing their higher studies, including Master’s programs. This makes Indian students one of the largest groups of international students in Germany. The CAGR was 26% during that period. Hence, if similar growth is seen consecutively, there is a fair chance of the total numbers increasing to 68,000 or more.
                                                        <br /><br />
                                                        So, what are you waiting for? Book your appointment with Aspirations Inc. and receive comprehensive guidance on obtaining a master’s degree and admission to renowned German universities.
                                                        <br /><br />
                                                        There are a number of reasons why Indian students pursue their Master’s in Germany. Here are the important ones:
                                                        <br /><br />
                                                        <b>1. Secure Work Visa :  </b> After completing the Master’s degree program, international students, including Indian students, can apply for an 18-month post-study work visa. This allows them to seek employment and gain work experience. After the master’s degree program, chances of eligibility for getting into the workforce in various domains become higher, such as technology, manufacturing, medicine, business management and much more.
                                                        <br /><br />
                                                        <b>2. Cost-effective learning : </b>

                                                        In Germany, the students receive a high-quality education at a free to just minimal tuition fees, especially at public universities. Mainly, students have just to pay a nominal administration fee in the state-regulated public universities. Students have the opportunity to pursue a master’s or PhD degree at a much more affordable cost as compared to many prestigious universities in the USA and UK! Moreover, students get the highest quality of education in Germany, which has made a remarkable recognition across the globe! The private universities do charge tuition fees, but with a fair return of providing top-notch education, and opportunities like internships, master thesis and job offers. Both public universities and private universities incorporate and impart the highest quality education that is acknowledged worldwide!
                                                        <br /><br />
                                                        <b>3. Indian and International Community : </b>

                                                        The Indian community is growing significantly in Germany due to its welcoming environment, high education quality, and growth opportunities for international students. You will find more international students, including  Indian students! This creates an inclusive space for Indian students to settle in Germany, and blend in the community and get the best of both Worlds!
                                                        <br /><br />
                                                        <b>4. Wide range of Master’s programs : </b>

                                                        Universities and academic institutions in Germany not only just offer bachelor programs but also offer a wide range of master's degrees at the postgraduate level. These master's degree programs are acknowledged worldwide, and one can find job opportunities based on these programs. The vast range of courses attracts international students to pursue Master’s programs in Germany.
                                                        <br /><br />
                                                    </p>
                                                </header>

                                                <section className="why-germany">
                                                    <h5 className='m-0'>The choice of subjects is wider and includes courses like: </h5>
                                                    <ul>
                                                        <li>Masters In Microelectronics,</li>
                                                        <li>Microsystem Engineering,</li>
                                                        <li>Renewable Energy,</li>
                                                        <li>E-Mobility,</li>
                                                        <li>Communications & Signal Processing,</li>
                                                        <li>Nanoelectronics System,</li>
                                                        <li>Urban Development,</li>
                                                        <li>Geomatics Infrastructure Planning,</li>
                                                        <li>3D Animation,</li>
                                                        <li>Optical Technology,</li>
                                                        <li>Construction and Real Estate,</li>
                                                        <li>International Management,</li>
                                                        <li>Health Care Management,</li>
                                                        <li>Photography</li>
                                                        <li>Artificial Intelligence,</li>
                                                        <li>Data Analytics,</li>
                                                        <li>Cyber Security,</li>
                                                        <li>Law and Business,</li>
                                                        <li>Sports and Event Management,</li>
                                                        <li>Visual and Experience Design,</li>
                                                        <li>Mba Supply Chain Management,</li>
                                                        <li>Digital Marketing Management,</li>
                                                        <li>Investment Banking,</li>
                                                        <li>Financial Risk Management,</li>
                                                        <li>Communication Design,</li>
                                                        <li>Software Engineering,</li>
                                                        <li>Game Design,</li>
                                                        <li>Data Science,</li>
                                                        <li>Supply Chain Management and Logistics,and much more…</li>
                                                    </ul>
                                                </section>

                                                <section className="programs-offered">
                                                    <h4 className='m-0'>Common eligibility criteria for admissions for a master degree program in Germany</h4>
                                                    {/* <p>German universities offer a vast range of Master's programs, including:</p> */}
                                                    <ol>
                                                        <li>Bachelor's degree from a recognized University </li>
                                                        <li>Your Secondary Education (10th standard) and Higher Secondary Education (12th Standard) Marksheets and Certificates </li>
                                                        <li>High School Diploma (if any) [Hochschulzugangsberechtigung (HZB)] – Copies of High School transcripts and diploma </li>
                                                        {/* <li>translated in the German language</li> */}
                                                        <li>.TOEFL, IELTS or PTE exam scores for evaluation of English language proficiency </li>
                                                        <li>GRE or GMAT exam course for assessing quantitative and analytical skills </li>
                                                        <li>TestDaF or DSH Test scores for the proof of German language proficiency (if required)</li>
                                                        <li>Relevant work experience of 2 to 5 years (required for some programs) </li>
                                                        <li>Proof of pre-university entrance exam</li>
                                                        <li>Translation of documents in English and/or German language that are not in English or German language </li>
                                                        <li>Cover letter </li>
                                                        <li>Photocopy of passport </li>
                                                        <li>CV or Resume </li>
                                                        <li>Statement of Purpose (SOP) </li>
                                                        <li>Letter of Recommendation (LOR) </li>
                                                        <li>Health Insurance proof </li>
                                                        <li>Medical Certificate </li>
                                                        <li>Annual Income of parents</li>
                                                        <li>Migration Certificate </li>
                                                        <li>Blocked Account </li>
                                                        <li>School Leaving Certificate</li>
                                                        <li>Residential proof </li>
                                                        <li>APS Certification </li>
                                                        <li>Any other documents that are specified by the desired universities or programs</li>

                                                    </ol>
                                                </section>

                                                <section className="eligibility-criteria">
                                                    <h4 className='m-0'>Types of master’s degree program </h4>
                                                    <p>There are several types of master's degree programs that are conducted by universities in Germany, and they can be categorised into the following:</p>
                                                    <b>Consecutive Master's degrees:</b> These programs are specially designed for students who have completed a related bachelor's degree in a relevant field. These degree programs are tailored to enhance the knowledge gained during undergraduate studies and further expand on it. Consecutive master's degrees generally do not charge tuition fees.
                                                    <br /><br />
                                                    <b>Non-consecutive Master’s degrees:</b> These degrees allow students from diverse academic backgrounds to apply for master’s degree courses in German universities. It often requires relevant work experience. Tuition fees may apply for the non-consecutive master’s courses.
                                                    <br /><br />
                                                    <h5 className='m-0'>General Structure of Master’s Degree Program in Germany</h5>

                                                    Germany implements the Bologna process for higher education. Hence higher education in Germany mainly incorporates the European format of learning. In this format, the bachelor's degree is considered the First Cycle, the Master's Degree is considered the Second Cycle, and the PhD is regarded as the Third Cycle!
                                                    <br /><br />
                                                    The duration of a master's degree in Germany lasts about 1 to 2 years, and it requires the completion of 120 ECTS (European Credit Transfer and Accumulation System) credits. A master's degree has 120 ECTS credits, of which 90 are allotted to modules, tests and coursework, and the remaining 30 are allotted for a dissertation.
                                                    <br /><br />
                                                    Master's programs in Germany usually have a duration of 2 years, with two or four semesters in total. It depends upon the choice of subject and the university regulations.
                                                    <br /><br />
                                                    <h5>Key requirements for a Master’s degree  in Germany </h5>
                                                    <b>Secure funding and Financial Resource Proof (Finanzierungsnachweis)</b>
                                                    <br />
                                                    International students, while planning to study in Germany, must have a financial resource of at least € 11,904 per year to qualify for a student Visa and to pursue a Master's Degree Program in Germany. This requirement can also be met in various other ways:
                                                    <ul>
                                                        <li>The student must have € 11,904 deposited in a German Blocked Account. A Blocked Account serves as proof of financial resources, increasing the possibility of expedited approval for a German student visa. </li>

                                                        <li>The student’s parents have to provide documentation of their income proof and financial resources. </li>

                                                        <li>Any person with permanent residence in Germany (Verpflichtungserklärung) whom the student knows can also guarantee to the Alien Registration Office as proof that the person is financially supporting the student for education and accommodation in Germany during the master's degree program. </li>

                                                        <li>Any scholarship that awards a certificate specifying the financial amount and coverage that is granted to the student for pursuing a master's degree program in Germany.</li>

                                                    </ul>
                                                </section>
                                                <section className="health-insurance">
                                                    <h4 className='m-0'>Health Insurance</h4>
                                                    <p>Health Insurance is mandatory for all international students applying for Master's programs in Germany. It’s recommended to start the process early.</p>
                                                    <ul>
                                                        <li>Health Insurance is mandatory for all international students, including  Indian students applying for the Master's programs in Germany.</li>
                                                        <li>It is recommended to start applying the health insurance process before getting admission to any German university. </li>
                                                        <li>A student can use a current health insurance policy if the country where the student resides has an agreement with Germany, mainly with a European Health Insurance card (EHIC). </li>
                                                        <li>In most cases, Private Health Insurance from other countries can also be taken into consideration.
                                                            Students can also choose to obtain Health Insurance through a provider in Germany</li>
                                                        <li>Students can also choose to obtain Health Insurance through a provider in Germany.</li>
                                                    </ul>
                                                </section>

                                                <section className="funding">
                                                    <h4 className='m-0'>Visa Application and Documents</h4>
                                                    <p>It is recommended that students start the German Student Visa application process and set up a visa interview at the German Embassy or Consulate in their home country before admission. During the visa interview, the student is required to present all documents and valid proofs.
                                                        <br /><br />
                                                        The following are the visa documents mainly required for Indian students while applying for studying Bachelor’s program in Germany:</p>
                                                    <ul>
                                                        <li>Duly filled out and signed national visa application form.</li>
                                                        <li>Two photocopies of your passport. </li>
                                                        <li>Birth certificate. </li>
                                                        <li>Valid passport. </li>
                                                        <li>Marriage certificate (if applicable). </li>
                                                        <li>Your child’s birth certificate (if applicable). </li>
                                                        <li>Recent passport-style photographs (up to 3). </li>
                                                        <li>Photocopies of previous German residence titles (if applicable).</li>
                                                    </ul>
                                                </section>





                                                <section className="aps-verification">
                                                    <h4 className='m-0'>APS Verification</h4>
                                                    <p>The APS certificate is mandatory for Indian students who are applying for a Master’s program in Germany. However, there are exceptions for those possessing German or EU-funded scholarships, PhD applicants whose supervisors do not require any APS verification, and students attending short-term courses (less than 90 days). A quick consultation with a German Education Consultancy in India can clear all your doubts regarding the APS certification process.
                                                        <br /><br />
                                                        <b>Documents required for APS Verification:</b>
                                                        <ul>
                                                            <li> Complete and signed application form
                                                            </li><li>Recent passport-sized photograph
                                                            </li><li>Aadhaar Card
                                                            </li><li>Passport
                                                            </li><li>Payment Receipt
                                                            </li><li>Academic documents
                                                            </li><li>Additional Documents
                                                            </li><li>Verification fee</li>
                                                        </ul>
                                                        <b>APS Certification Application Process: </b>
                                                        <br />
                                                        <ul>
                                                            <li> Collect all the required documents. Make sure the documents are latest and valid. </li>
                                                            <li>Fill out the application form online. </li>
                                                            <li>Pay the verification fee </li>
                                                            <li>Submit the APS application form with the required documents. (by post or in person)  </li>
                                                            <li>Attend the interview (if required)</li>
                                                            <li>Get the APS Certificate after successful verification </li>
                                                        </ul>
                                                        <br />
                                                        The APS Verification process takes around 3 to 4 weeks, and the students might have to attend a personal interview, especially when the student is applying for a bachelor’s program or any long-duration program in Germany.
                                                        <br />
                                                        APS (Akademische Prüfstelle) or the Academic Evaluation Centre evaluates the documents for their authenticity. It verifies the authenticity of an applicant's (student’s) academic qualifications and confirms eligibility to enrol in a German university. For an Indian student, who is about to pursue higher education in Germany, it is mandatory to have a valid APS Certification. APS Certificate in India is issued by the Academic Evaluation Centre at the German Embassy in New Delhi.
                                                        <br /><br />
                                                        <h5 className='m-0'>Evidence of Previous Qualifications</h5>

                                                        You have to submit copies of previous education qualification (marksheet and certificates) along with school leaving certificate, if required.
                                                        <br />
                                                        <br />
                                                        <b>Higher education Entrance qualification (HZB) </b>
                                                        <br />
                                                        To study in Germany, a student must have a University Entrance Qualification, which is known as “Hochschulzugangsberechtigung” or “HZB” in short. HZB is a collective term used for the educational certificate that qualifies and considers a student to study at any German university. This qualification is necessary for both domestic and international students who are seeking admission to higher education in Germany.
                                                        <br />
                                                        <b> Proof of German and English Language proficiency</b> <br />
                                                        In order to pursue higher education in Germany, one must have German and English language proficiency. Most universities in Germany require proof of German and English language proficiency before confirming admission into their programs. The following exams and language courses are acknowledged and accepted by German universities:
                                                        <br /> <b>For German language proficiency, the commonly accepted proofs are: </b>
                                                        <ul>
                                                            <li>  DSH (German Language University entrance examination)</li>
                                                            <li> Test DaF (Test of German as a Foreign Language) </li>
                                                            <li> Goethe Institute - Indian students must show proof of German language proficiency from the Goethe Institute. </li>
                                                            <li> DSD (German language diploma)</li>
                                                        </ul>
                                                        <b>Now, for English language proficiency, the students need to prove:</b>
                                                        <ul>
                                                            <li>  TOEFL (Test of English as a Foreign Language) </li>
                                                            <li> IELTS (International English Language Testing System) </li>
                                                            <li> Other language programs, such as Cambridge English Language Certificates: FCE, CAE, CPE </li>

                                                        </ul>
                                                        Mainly, the Universities of Germany accept minimum B2 level language proficiency in both the German and the English language.
                                                    </p>
                                                </section>

                                                <section className="accommodation">
                                                    <h4 className='m-0'>Scholarship opportunities</h4>
                                                    <p>Students can opt for scholarships to fund their higher education in Germany. The student needs to disclose secure funding, Block Account with € 11904, parents' annual income proof etc.
                                                        <br /><br />
                                                        However, a scholarship that covers the whole expense of studies in Germany can also help in getting admission to the universities. The DAAD scholarship database has around 200 scholarship opportunities for students coming from various backgrounds. Many German universities also offer their own grants based on eligibility criteria.</p>
                                                    <h5 className='m-0'>Proof of the Purpose of Stay in Germany</h5>
                                                    <p>“Purpose of Stay” proof is often required when you are travelling to Germany for your higher education. To attain the “purpose of stay” proof, you must need the following main documents:
                                                        <ul>
                                                            <li>Proof of Admission: Confirmation of admission at your college or university, with details of language of instruction, program start date and program duration is duly mentioned. </li>

                                                            <li>Proof of admission in Studienkolleg –Validation is required as an applicant, with confirmation of admission for Studienkolleg.
                                                            </li>
                                                            <li>Proof of conditional university admission – confirmation of admission in a 6-month course, of minimum of 18 hours weekly on the courses, especially language courses (Intesiv – Sprachkurs), is required. Or one may submit the proof of three months of payment for the course. </li>
                                                        </ul>
                                                        <b>Accommodation Options</b><br /><br />
                                                        There are many types of accommodation options for international students studying in Germany, such as:
                                                        <ul>
                                                            <li>Private Accommodations: Private apartments, shared flats (Wohngemeinschaft or WG), or rooms in private homes.</li>
                                                            <li>Student Halls of Residence (Dormitories)</li>
                                                            <li>Inexpensive hotels</li>
                                                            <li>Youth hostels</li>
                                                            <li>Guest houses</li>
                                                            <li>University Assistance (if applicable)</li>
                                                        </ul>
                                                        Factors like location, affordability, privacy, cost, ambience, surroundings, safety, transportation and connectivity and available necessary and emergency facilities are to be taken into consideration while choosing the correct accommodation for Indian and international students. Some Universities also assist in providing accommodation to the students. In such cases, students must contact the university and seek for assistance.
                                                    </p>
                                                </section>


                                                <section className="frequently-asked-questions">
                                                    <h4>Frequently Asked Questions (FAQs)</h4>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(1)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 1 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What kind of universities offer Master’s Degree Programs?
                                                        </h6>
                                                        {openFAQ === 1 && (
                                                            <p>Public universities regulated by the State, provide all kinds of higher academic education programs. Private universities and independent academic institutions also provide higher education, including Master’s programs, to both National and international students.
                                                                <br />
                                                                <br />
                                                                The following are the types of Universities and Academic institutions which mainly offer Master’s Degree programs:
                                                                <ul>
                                                                    <li>Technical universities </li>
                                                                    <li>Universities of Applied Science </li>
                                                                    <li>Research Universities </li>
                                                                    <li>College of Art, Film and Music</li>
                                                                </ul>
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(2)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 2 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;How many years does it take to complete a Master’s Degree Program?
                                                        </h6>
                                                        {openFAQ === 2 && (
                                                            <p>The duration of a master's degree in Germany usually lasts 1 to 2 years, and it requires the completion of 120 (European Credit Transfer and Accumulation System) credits. A Master's degree program has 120 ECTS credits, of which 90 are allotted to modules, tests and coursework, and the remaining 30 are allotted for a dissertation. Master's programs in Germany usually have a duration of 2 years, with two or four semesters in total. It depends upon the choice of subject and the university regulations.</p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(3)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 3 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;How many ECTS credits are typically awarded for a German Master's degree?
                                                        </h6>
                                                        {openFAQ === 3 && (
                                                            <p>In Master’s Degree Program, generally 120 ECTS (European Credit Transfer and Accumulation System) credits are awarded. The exact numbers depend upon the specific programs and courses, and upon university regulations.</p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(4)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 4 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What is Numerus Clausus (NC)?
                                                        </h6>
                                                        {openFAQ === 4 && (
                                                            <p>For the Master's programs offered in Germany, Numerous Clausus (NC) is a kind of restricted admission system where the spots are limited, and selection is based on criteria like academic performance and education qualifications. Numerous Clausus (NC) is a program that is designed to control overcrowding and select only the eligible students.
                                                                <br /><br />
                                                                Students who already have a German degree and are from the EU/EEA often need to apply via the Hochschulstart platform. In some cases, the students must apply via  Hochschulstart and Uni-assist. Uni-assist is a centralized application service in Germany that assists and simplifies the application process to universities for international students. Approximately 180 universities use it to process applications from students outside Germany.</p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(5)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 5 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can a student apply for a Master's Degree before completing his or her undergraduate program?
                                                        </h6>
                                                        {openFAQ === 5 && (
                                                            <p>Yes, a student can apply for a Master's Degree program in Germany during the final year of their Bachelor's program. In such cases, the student must provide proof of current academic details and progress of the undergraduate program. The university providing a master's degree can also ask for an expected graduation completion date as part of their application process. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(6)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 6 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What are the languages of instruction in Master’s degree programs in Germany?
                                                        </h6>
                                                        {openFAQ === 6 && (
                                                            <p>Instructions are delivered in mostly German language. Some universities conduct lectures and academic sessions in the English language. Most universities offer instructions in both German and English language. It is mandatory to learn German and English language and have at least B2-level language proficiency in both languages. The language criteria will be mentioned on the respective University sites. In order to be admitted to German universities and academic institutions, most higher educational programs require proof of proficiency in both German and English. It's important to learn both languages to effectively communicate and interact with the people in Germany. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(7)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 7 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can I work in Germany while undergoing a Master’s Degree Program?
                                                        </h6>
                                                        {openFAQ === 7 && (
                                                            <p>Yes, students coming from all nationalities and countries can work while studying Master's degree in Germany. The Federal Office for Migration and Refugees (BAMF) has announced that international students can also work under certain criteria EU/EEA students can work up to 20 hours per week, while NON-EU students can work up to 120 full days or 240 half days per year. However, there are regulations on self-employment and freelancing job roles. The academic assistants have to inform the foreign office to work in Germany, even though they have no workday limitations. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(8)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 8 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Do students have to submit a Master's Thesis in Germany?
                                                        </h6>
                                                        {openFAQ === 8 && (
                                                            <p>It depends upon the University regulations, their structure and process of pedagogy, and the subject a student has chosen. If a Master's Thesis is required in the final year of the program, the university will ask to provide a master's thesis. In such cases, the students have to work on their thesis project, which is guided by a professor. This helps the students search for subject-related issues and use research methods. The master's thesis can be in English or German as instructed by the University, and it has deadlines which are around 6 months from the start of the project. This helps students to understand and work on research methodology and findings </p>
                                                        )}
                                                    </div>

                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}
