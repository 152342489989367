import React, { useState } from 'react'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'; // Using Font Awesome for icons

import './BachelorsPage.css';
import PageHeaderTwo from '../../component/layout/pageheader-2';
const title = "BACHELOR’S DEGREE IN GERMANY";
const desc = "Aspirations Inc. is here to assist you. Before proceeding, you must assess facts and data to support your decision firmly.Around 43000 Indian students went to study in Germany in 2022-23, with a CAGR of 26%! Hence, if we assume the CAGR remains the same, in FY 2025, around 68,000 Indian students will be flying to Germany to pursue their academic education and research! ";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";

export const Bachelors_In_Germany = () => {
    const [openFAQ, setOpenFAQ] = useState(null); // Track which FAQ is open

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ by index
    };
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/bachelors-in-germoney.jpg" alt="bachelors-in-germoney" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>

                                <ul >
                                    <li ><b>Are you wondering why Germany is the best country to pursue a Bachelor’s degree?</b>
                                    </li>
                                    <li><b>Are you looking to study for a Bachelor’s in Germany?</b> </li>
                                    <li><b>Are you looking for a one-stop solution for securing admission in Germany?</b></li>
                                </ul>
                                {/* <p className="phs-desc">{desc}</p> */}
                                <p> Aspirations Inc. is here to assist you. Before proceeding, you must assess facts and data to support your decision firmly.</p>
                                <p>Around 43000 Indian students went to study in Germany in 2022-23, with a CAGR of 26%! Hence, if we assume the CAGR remains the same, in FY 2025, around 68,000 Indian students will be flying to Germany to pursue their academic education and research!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <div className="phd-page">
                                                <section className="why-germany">
                                                    <h4>Why Should Indian Students Study a Bachelor's in Germany?</h4>
                                                    <p>
                                                        <ol>
                                                            <li ><b>Highest Quality Education </b><br />
                                                                Germany offers top-notch education quality and an environment that nurtures the highest state of education. Its appeal as a destination for higher education attracts many international students, including those from India. Universities and institutions in Germany impart theoretical knowledge and a practical approach to learning. Exams, group projects, oral assessments, assignments, thorough progress checks, and active participation are encouraged and incorporated into most programs.</li><br />
                                                            <li><b>Minimal Education Fees</b> <br />
                                                                Germany is known for its free to minimal tuition fees at public universities. Students mostly have to pay a nominal administration fee. Moreover, the total cost of the complete program is pocket-friendly than most of the renowned universities in the USA and the UK. Furthermore, students get the highest quality of education in Germany, which has made remarkable recognition across the globe! Private universities also charge tuition fees, with the return of providing top-notch education and opportunities like internships, master's thesis, and job offers.
                                                            </li><br />
                                                            <li><b>Countless Courses Options</b><br />
                                                                Germany is not only known for its Bachelor’s and Master’s degree courses, but it also offers many short-term and long-duration courses that are recognised worldwide and help students land high-paying jobs. Apart from traditional courses, Germany also offers various programs in various fields that align with students’ interests and career goals.</li><br />
                                                            <li><b>Employment and research opportunities</b> <br />
                                                                Students can do research and work full-time or part-time in Germany. They can pursue part-time jobs during their studies or semester breaks. They can also extend their visas and look for employment.
                                                            </li><br />
                                                            <li><b>Indian and International Community </b><br />
                                                                The Indian community is growing significantly in Germany due to its welcoming environment and growth opportunities for international students. You will find more Indian students coming to Germany in significant numbers to receive higher education and job opportunities! Many Indian people are living in Germany permanently. An Indian student new to this country can connect with their fellow citizens. This creates a welcoming environment for Indian students to integrate into the German community and enjoy the best of both worlds!</li>
                                                        </ol>
                                                        We also want to highlight the <i><b>three main factors</b></i> that most Indian students consider when choosing a bachelor's program in Germany:<br /><br />
                                                        <b>Universities:</b> Universities of Germany and academic institutions offer a wide range of Bachelor's programs. The wide variety of subjects allows students to choose their desired subjects for the Bachelor's program.
                                                        You can get a wide range of subjects covered in Bachelor's degree programs in Germany, such as Bachelor’s Degrees in:
                                                        <br />
                                                        <ul>
                                                            <li>Music Business,</li>
                                                            <li>Games Technology,   </li>
                                                            <li>Game Design,</li>
                                                            <li>Illustration</li>
                                                            <li>Screen Acting,</li>
                                                            <li>Music Production </li>
                                                            <li>Photography </li>
                                                            <li>User Experience Design and Content Creation</li>
                                                            <li>International Business Management, </li>
                                                            <li>Fashion Management</li>
                                                            <li>Brand Management,</li>
                                                            <li>International Business Administration - General or Digital Marketing focus </li>

                                                        </ul><br />
                                                        <p><b>And the list goes on.</b></p>

                                                       <p> There are more than 17000 courses offered in Germany for higher studies! </p>

                                                        <p>All the courses and Bachelor's programs offered by German universities and institutions are acknowledged worldwide. Hence, it becomes easier for students to pursue higher studies or<br />
                                                        seek research opportunities. Bachelor’s degree programs offered by Germany’s universities and academic institutions can also enhance students' employment opportunities.
                                                        </p>
                                                        Most of the public universities are almost free of cost, and students only pay a nominal administration fee. Since most public universities are government-owned, the quality of education is top-notch, with many students applying for it. Private universities offer many perks besides superior academic education, such as internship opportunities, master's thesis, and job employment. They are a noteworthy and rewarding investment for students!<br /><br />
                                                        <b>Socio-Economic Landscape:</b> Germany is mainly responsible for facilitating an unparalleled education system, as well as facets like culture, climate, economy, and standard of living. It nurtures the top-tier progressive ambience for studies, research, jobs and businesses! Germany’s solid economic backbone and ties with the world’s powerful countries nurture a powerhouse of talent and a workforce from world-renowned German universities and academic institutions. Germany welcomes international students from across the globe. It provides them with the best skills and higher education. Indian students are choosing Germany to attain globally acknowledged Bachelor, Master, PhD and other programs.
                                                        <br /><br />
                                                        <b>Students:</b> The students are the most prominent pillars contributing to such academic excellence in Germany! The interactive programs, the cultural exchange, the learning of German languages, the active participation in assessments and assignments, and every effort of students make the academic backbone of Germany stronger. Students get to work between semester breaks, attain internship opportunities, extend their visas, search for job opportunities, and even pursue research options! The possibilities are infinite, and there are always opportunities to thrive and succeed in Germany. Indian students in large numbers are studying in Germany and have made their dream life come to reality. Many international students, including Indian students, aspire to build an outstanding career.
                                                        <br />
                                                        These three aspects are the primary considerations that attract international students to pursue higher studies, such as Bachelor’s, Master’s, and Ph. D.s, in Germany.
                                                        <br /><br />
                                                        <h4>Are you ready to be part of it?</h4>
                                                        <h5>Admission Requirement to study Bachelor’s in Germany</h5>
                                                        Now that you are motivated enough to pursue a Bachelor’s degree in Germany let’s look at the main requirements you need to have to apply for a Bachelor’s degree:
                                                    </p>
                                                </section>
                                                <section>
                                                    <ol>
                                                        <li>Your Secondary Education (10th standard) and Higher Secondary Education (12th Standard) Marksheets and Certificates</li>
                                                        <li>High School Diploma (if any) [Hochschulzugangsberechtigung (HZB)] – Copies of High School transcripts and diploma translated into the German language</li>
                                                        <li>IELTS or TOEFL Score </li>
                                                        <li>TestAS Score</li>
                                                        <li>Letter of Recommendation (LOR) from the school</li>
                                                        <li>Statement of Purpose (SOP)</li>
                                                        <li>Proof of Pre-university entrance exam </li>
                                                        <li>Birth Certificate </li>
                                                        <li>Migration Certificate</li>
                                                        <li>Medical Certificate </li>
                                                        <li>APS Certification</li>
                                                        <li>Blocked Account </li>
                                                        <li>School Leaving Certificate</li>
                                                        <li>Passport Copy</li>
                                                        <li>CV/Resume</li>
                                                        <li>Subject-specific requirements – mentioned on the desired Universities’ websites </li>
                                                        <li>Studienkolleg (University Preparatory Course)</li>

                                                    </ol>
                                                    <h6 className='m-0'>WHAT IS A STUDIENKOLLEG?</h6>
                                                    <p>Studienkolleg is a preparatory course in Germany designed to help international students qualify for admission to German universities, particularly those who initially need to meet the admission criteria. The final exam (Feststellungsprüfung or FSP) permits international students to attend educational programs like degree programs in Germany! Studienkolleg usually lasts one year, with mainly two semesters. The program for the final exam aims to narrow the academic culture gap and align students with the German education system. </p>
                                                    <h6>WHAT ELSE YOU NEED TO PREPARE BEFORE PURSUING BACHELORS IN GERMANY?</h6>
                                                    <h6 className='m-0'>Funding:</h6>
                                                    <p>Suppose you are aiming to pursue any bachelor's program in Germany. In that case, you must have secured funding to cover your expenses in a blocked account. You can also aim to meet your financial security by the following methods:</p>
                                                    <ul>
                                                        <li>A bank guarantee that a German Bank has issued.</li>
                                                        <li>Letter of commitment along with proof of parents' income.</li>
                                                        <li>Commitment letter from any of your German residents (Verpflichtungserklärung) who will cover the study cost. </li>
                                                        <li>Any scholarship award certificate that you can avail yourself of.</li>

                                                    </ul>
                                                    <h6 className='m-0'>Visa:</h6>
                                                    <p>Non-EEA and non-EU international students must hold a student visa to study in Germany. Visa criteria vary from country to country, depending on the type of course you are willing to pursue and its duration.</p>
                                                    <ul>
                                                        <li>If you are looking for a short-term course lasting up to 90 days (3 months), you will require a Schengen (C Visa) Visa.</li>
                                                        <li>Suppose you have been studying for more than three months. If you have enrolled in a long-duration program, you will need a national visa (D Visa) and a resident permit once you arrive in Germany</li>
                                                        <li>There are three German National Visa (D Visa) types: German student Visa, German Student Applicant Visa, and German language course visa.</li>
                                                    </ul>
                                                    <h6 className='m-0'>Visa Documents: </h6>
                                                    <p>The following are the visa documents mainly required for Indian students while applying for a Bachelor’s program in Germany: </p>
                                                    <ul>
                                                        <li>I duly filled out and signed the national visa application form. </li>
                                                        <li>Two photocopies of your passport.</li>
                                                        <li>Birth certificate. </li>
                                                        <li>Valid passport. </li>
                                                        <li>Marriage certificate (if applicable). </li>
                                                        <li>Your child’s birth certificate (if applicable).</li>
                                                        <li>Recent passport-style photographs (up to 3).</li>
                                                        <li>Photocopies of previous German residence titles (if applicable)</li>
                                                    </ul>
                                                    <h6 className='m-0'>APS Verification: </h6>
                                                    <p>The APS certificate is mandatory for Indian students applying for Bachelor's programs in Germany. However, there are exceptions for those who have received German or EU-funded scholarships, PhD applicants whose supervisors do not require it, and students attending short-term courses (less than 90 days).</p>
                                                    <h6 className='m-0'>Documents required for APS Verification: </h6>
                                                    <ul>
                                                        <li>Complete and sign the application form</li>
                                                        <li>Recent passport-sized photograph</li>
                                                        <li>Aadhaar Card </li>
                                                        <li>Passport </li>
                                                        <li>Payment Receipt </li>
                                                        <li>Academic documents </li>
                                                        <li>Language proficiency Certificate (if it is applicable)</li>
                                                        <li>Additional Documents </li>
                                                    </ul>
                                                    <p>The APS Verification process takes 3 to 4 weeks, and students might have to attend a personal interview, especially when applying for a bachelor's or any long-duration program in Germany. </p>
                                                    <h6 className='m-0'>Proof of Financial Resources (Finanzierungsnachweis)</h6>
                                                    <ol>
                                                        <li>As of 2025, you must have a minimum of €11,904 in your blocked bank account when </li>
                                                        <li>applying for a German student visa. You can also draw a maximum of €992 monthly from </li>
                                                        <li>your blocked account.</li>
                                                        <li>You should also have proof of your parent's income record to cover all your expenses.</li>
                                                        <li>Scholarship award certificate that covers your expenses (if applicable)</li>
                                                        <li>Letter of commitment from any of your German residents (Verpflichtungserklärung) who will cover the study cost.</li>
                                                        <li>Bank guarantee issued by a recognised bank or a German bank</li>
                                                    </ol>
                                                    <h6 className='m-0'>Evidence of Previous Qualifications </h6>
                                                    <p>If necessary, you must provide copies of your previous education qualifications, including mark sheets and certificates, and your school leaving certificate.</p>
                                                    <h6 className='m-0'>Health Insurance</h6>
                                                    <p>Health insurance is a must if you are applying to German universities. You can either have your existing health insurance, get new health insurance, or get German Health insurance to qualify for the Visa. </p>
                                                    <h6 className='m-0'>Proof of German or English Proficiency</h6>
                                                    <p>Suppose you are opting to study in Germany. In that case, you must often prove German and English proficiency. Students may have to show evidence of IELTS or TOEFL TestAS score for language proficiency.</p>
                                                    <h6 className='m-0'>Proof of the Purpose of Stay in Germany </h6>
                                                    <p>"Purpose of Stay" proof is often required when travelling to Germany for higher education. To attain the "purpose of stay" proof, you must need the following primary documents:</p>
                                                    <ul>
                                                        <li>Proof of Admission: Confirmation of admission to your college or university is provided, including details of the language of instruction, program start date, and program duration.</li>
                                                        <li>Proof of admission in Studienkolleg –Please remember the following text: "Validation is required for applicants, along with confirmation of admission to Studienkolleg</li>
                                                        <li>Proof of conditional university admission – confirmation of admission in a 6-month course of a minimum of 18 hours weekly on courses, especially language course (Intesiv – Sprachkurs) is required. Or one may submit the proof of three months of payment for the course.</li>
                                                    </ul>
                                                    <h6 className='m-0'>HOW TO APPLY FOR BACHELOR’S PROGRAM IN GERMANY? </h6>
                                                    <ul>
                                                        <li>Research where do you want to study and what do you want to study </li>
                                                        <li>Contact the best education consultancy near you </li>
                                                        <li>Book an appointment</li>
                                                        <li>Take consultation and all-inclusive admission support </li>
                                                        <li>Set all your documents for admission, Visa and immigration, APS Verification</li>
                                                        <li>Fill out the university application form </li>
                                                        <li>Visa and immigration application </li>
                                                        <li>Wait for the final confirmation of admission </li>
                                                        <li>Apply for a Visa </li>
                                                        <li>Evaluation of all the supporting documents </li>
                                                        <li>Fulfil all the financial and banking procedures (funding and blocked account)</li>
                                                    </ul>
                                                </section>
                                                <section className="frequently-asked-questions">
                                                    <h4>Frequently Asked Questions (FAQs)</h4>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(1)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 1 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Is Germany a Good Country for a Bachelor’s Program?
                                                        </h6>
                                                        {openFAQ === 1 && (
                                                            <p>Yes. Germany is among the most popular places for international students to pursue bachelor's programs. .</p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(2)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 2 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Do Indian students prefer Germany for higher studies?
                                                        </h6>
                                                        {openFAQ === 2 && (
                                                            <p>Yes. In 2022-23, almost 43000 Indian students enrolled in Germany's education system for various courses and programs, and the growth increased by a CAGR of 26%. Many Indian students prefer Germany over other countries when studying abroad. </p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(3)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 3 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What is the cost of studying for a bachelor's degree in Germany?
                                                        </h6>
                                                        {openFAQ === 3 && (
                                                            <p>The cost varies according to the universities' fees. Generally, most public universities charge no tuition fees. Students only pay a nominal administration fee. Private universities, however, have higher tuition fees but with rewarding perks like providing top-notch education, internship opportunities, master's theses, and job offers.</p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(4)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 4 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Are there any entrance exams or interviews for Bachelor's programs in Germany?
                                                        </h6>
                                                        {openFAQ === 4 && (
                                                            <p>This can vary according to the universities’ requirements. Some universities may require entrance exams and interviews, while others may only conduct tests or interviews. Some universities may only verify students' documents and previous academic qualifications. If the university requires it, students must present their passing scores and certifications for the Language Proficiency Tests, IELTS, TestAS, TOEFL, and Studienkolleg before admission.</p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(5)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 5 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can students avail of any bachelor's program taught in English?
                                                        </h6>
                                                        {openFAQ === 5 && (
                                                            <p>Numerous universities and academic institutions in Germany offer instruction in the English language and prioritise English. However, it is recommended that students learn German from the Goethe Institute, recognised by the German Embassy, for a better understanding of the language. Students must interact with German professors, authorities, and residents. IELTS and TOEFL language tests are also necessary to prove English language proficiency in some universities, as students opt for English language instruction in the programs. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(6)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 6 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;How do you meet Germany's Higher Education Entrance Qualification?
                                                        </h6>
                                                        {openFAQ === 6 && (
                                                            <p>Students need to check if their school-leaving certificate from their home country is recognised as an HZB in Germany using the DAAD or Anabin databases. Furthermore, most German universities require a minimum German language proficiency of B1 or B2 level, according to the Common European Framework of Reference (CEFR).
                                                                A student may be required to submit a Letter of Recommendation (LOR), CV or resume, transcript, Statement of Purpose (SOP), work samples, and motivation letters. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(7)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 7 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What is the duration of the bachelor's program in Germany?
                                                        </h6>
                                                        {openFAQ === 7 && (
                                                            <p>The course duration of the Bachelor’s program in Germany is 3-4 years, depending upon the subject. The course duration is six years if the student has opted for a Bachelor's in Germany. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(8)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 8 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can a student work while studying for a bachelor’s degree in Germany?
                                                        </h6>
                                                        {openFAQ === 8 && (
                                                            <p>Yes, a student can work while studying for a bachelor’s degree in Germany, which helps students get practical work experience and an extra source of income. At the same time, EU/EEA (European Union/European Economic Area) students can work up to 20 hours a week during the lecture period without any insurance contributions. Non-EU/EEA students can work 140 full days or 280 half days per year without requiring approval from the Federal Recruitment Agency. EU and non-EU can work during semester breaks without any restrictions.   – Do confirm this part </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(9)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 9 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;What are the accommodation options for international students in Germany?
                                                        </h6>
                                                        {openFAQ === 9 && (
                                                            <p>There are many types of accommodation options for international students studying in Germany, such as:
                                                                <ul>
                                                                    <li>Private Accommodations: Private apartments, shared flats (Wohngemeinschaft or WG), or rooms in private homes.</li>
                                                                    <li>Student Halls of Residence (Dormitories)</li>
                                                                    <li>Inexpensive hotels</li>
                                                                    <li>Youth hostels</li>
                                                                    <li>Guest houses</li>
                                                                    <li>University Assistance (if applicable)</li>
                                                                </ul>
                                                                When choosing accommodations for Indian and international students studying for their Bachelor's degree in Germany, it's essential to consider factors such as location, affordability, privacy, cost, ambience, surroundings, safety, transportation and connectivity, and the availability of necessary and emergency facilities.  </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(10)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 10 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can students bring their family while pursuing any program such as a bachelor’s in Germany?
                                                        </h6>
                                                        {openFAQ === 10 && (
                                                            <p>A student can bring the family to visit or stay if they meet specific criteria, such as a valid visa, valid family visa, residence permit, marriage or registered partnership, ability to support a family without any public assistance, and family insurance coverage. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(11)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 11 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Is a bachelor’s degree from Germany valid in India?
                                                        </h6>
                                                        {openFAQ === 11 && (
                                                            <p>Yes. A bachelor's degree from Germany is valid in India if pursued by an authorised university or academic institution in Germany. The degree is recognised by the Government of India and the Indian education system. </p>
                                                        )}
                                                    </div>
                                                    <div className="faq-item">
                                                        <h6 onClick={() => toggleFAQ(12)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {openFAQ === 12 ? <FaChevronDown /> : <FaChevronRight />}
                                                            &nbsp;Can I get permanent residency after studying in Germany?
                                                        </h6>
                                                        {openFAQ === 12 && (
                                                            <p>Permanent residency in Germany can be obtained after studying in Germany, with the fulfilment of specific criteria like:
                                                                <ul>
                                                                    <li>Completion of Bachelor's degree or higher education program,</li>
                                                                    <li>An 18-month job-seeker visa after completion of graduation,</li>
                                                                    <li>Residence permit for employment after securing employment and confirmation of the employment</li>
                                                                    <li>Fulfilling the residency requirements of living at least 33 months on a temporary residence permit. </li>
                                                                    <li>German language skills—mainly B1—are required. One can attain a German language certificate by enrolling in courses and programs from accredited institutions or universities.</li>
                                                                    <li>Financial stability, health insurance, and stable income from a job to have self-sufficiency</li>
                                                                    <li>Must have made contributions to statutory pension insurance for at least 60 months
                                                                        One can apply for a permanent residence permit (Niederlassungserlaubnis) at the local immigration office once all the above conditions are met.</li>
                                                                </ul> </p>
                                                        )}
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
}
