import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import React, { useEffect, useState } from "react";
// import "./Allcontactdetails.css";
import {
    Box,
    Button,
    Center,
    Text,
    useToast,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../url.js";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import PageHeader from "../component/layout/pageheader.jsx";




const SearchNone = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(30);
    const toast = useToast();



    const fetchData = async () => {
        try {
            const response = await axios.get(`${mod.api_url}/contact/details`);
            setData(response.data);
        } catch (error) {
            toast({
                title: "Facing issue on Get Jobs backend issue",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Calculate the indices of the first and last items on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);
    return (
        <Fragment>
            <Header />
            <PageHeader  title={'4 Results found for: Business'} curPage={'Search Result'} />

            <Center>
                <div className="Allcontact-details">
                    <Box width="100%">
                        <Text fontSize="xx-large" fontWeight="600" color="hotpink">
                            All Contact-Us Details
                        </Text>
                    </Box>
                    {data && data.length > 0 ? (
                        <>
                            <TableContainer>
                                <Table size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th color="white" fontSize="large">
                                                Name
                                            </Th>
                                            <Th color="white" fontSize="large">
                                                Mobile
                                            </Th>
                                            <Th color="white" fontSize="large">
                                                Email
                                            </Th>
                                            <Th color="white" fontSize="large">
                                                Date
                                            </Th>
                                            <Th color="white" fontSize="large">
                                                Message
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {currentItems.map((item) => (
                                            <Tr key={item._id}>
                                                <Td>{item.name ? item.name : "null"}</Td>
                                                <Td>{item.mobile ? item.mobile : "null"}</Td>
                                                <Td>{item.email ? item.email : "null"}</Td>
                                                <Td>{item.createdAt.slice(0, 10)}</Td>
                                                <Td>{item.message ? item.message : "null"}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                    <Tfoot>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Mobile</Th>
                                            <Th>Email</Th>
                                            <Th>Date</Th>
                                            <Th>Message</Th>
                                        </Tr>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="center" mt={4}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => paginate(index + 1)}
                                        colorScheme="pink"
                                        mx={1}
                                        marginBottom={3}
                                        disabled={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </Box>
                        </>
                    ) : (
                        <Text>No data available</Text>
                    )}
                </div>
            </Center>
            <Footer />
        </Fragment>
    );
}

export default SearchNone;