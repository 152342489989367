import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Import WhatsApp icon

const WhatsappLog = () => {
    const whatsappNumber = "+919310072343"; // Replace with your phone number

    const handleWhatsAppClick = () => {
        const message = encodeURIComponent("Hello! I need assistance.");
        const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <>
            {/* Other Whatsapp content */}
            <div style={styles.whatsappIconContainer} onClick={handleWhatsAppClick}>
                <FontAwesomeIcon icon={faWhatsapp} size="3x" color="#25D366" />
                <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
            </div>
        </>
    );
};

const styles = {
    whatsappIconContainer: {
        position: 'fixed',
        bottom: '20px',  // Distance from the bottom of the screen
        right: '20px',   // Distance from the right of the screen
        cursor: 'pointer',
        // backgroundColor: '#25D366',
        borderRadius: '50%',
        padding: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,  // Ensures the icon stays on top of other elements
    },
};

export default WhatsappLog;
