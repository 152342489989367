import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import { Link } from "react-router-dom";
const subTitle = "Aspiration Inc";
const title = "Good Qualification Services And Better Skills";
const desc = " Aspirations Inc. is your dedicated partner in achieving higher education excellence in Germany. We empower ambitious individuals to become global leaders through strategic guidance, cultural immersion, and unwavering support.";

const year = "30+";
const expareance = "Years Of Experiences";



const aboutList = [

    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Personalized Approach',
        desc: 'Our mission is to create evolutionary educational experiences by matching students with the perfect university and programme to align with their Aptitude, Academic goals and Aspirations. From comprehensive counselling and application assistance to visa support and post-arrival care, we provide end-to-end support for students throughout their academic journey.'
    },
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Authorized Representative ',
        desc: 'As authorised representatives of SRH University, we offer unparalleled access to innovative programmes and a strong network of alumni who understand the challenges and opportunities faced by Indian students',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Expert Guidance',
        desc: 'Our team of experienced counsellors is committed to helping you navigate the complexities of higher education in Germany and achieve your full potential. These expert counsellors provide personalised mentorship, transparent guidance, nurture a strong foundation of knowledge and support, thus ensuring students are well-prepared for their academic journey in Germany.',
    },


    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Commitment to Success',
        desc: 'This commitment to student success is at the core of our mission and is reflected in our name, Aspirations Inc. We believe in the power of education to transform lives. Join us as we inspire and empower the next generation of global leaders.    ',
    },
]


const AboutPage = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="pageheader-content text-center">
                                <h2>{'About Our Aspiration Inc'}</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{'About'}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="mt-10"

            >
                {/* <div className="container">
                    <div className="row " >
                        <div className="col-12">
                            <div className="pageheader-content text-center" style={{
                                backgroundImage: `url('https://keystoneacademic-res.cloudinary.com/image/upload/v1700151341/Master_s_Degree_Guide_3_psj38f.png')`,
                                backgroundSize: 'cover', // Optional: This ensures the image covers the entire div
                                backgroundPosition: 'center', // Optional: This centers the background image
                                height: '140vh', // Optional: Set the height of the div if 
                                marginTop: '16%',
                            }}>
                                <h2>{title}</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            About us
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    {/* <span className="subtitle">{subTitle}</span> */}
                                    {/* <h2 className="title">{subTitle}</h2> */}
                                    {/* <p>{desc}</p> */}
                                    <p><b>Aspirations Inc.</b> is your dedicated partner in achieving higher education excellence in Germany. We empower ambitious individuals to become global leaders through strategic guidance, cultural immersion, and unwavering support.</p>

                                </div>
                                <div className="section-wrappere">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (<>
                                            <li key={i}>
                                                <div className="sr-left">
                                                    {/* <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /> */}
                                                </div>
                                                <div className="sr-right">
                                                    {/* <h5>{val.title}</h5> */}
                                                    <p>{val.desc}</p>
                                                </div>

                                            </li>

                                        </>))}
                                    </ul>
                                    <b>Aim High, Achieve More <br />
                                        Your Dream - Our Mission</b>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.jpg" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/02.jpg" alt="about" />
                                </div>
                                {/* <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Student /> */}
            {/* <Instructor /> */}
            {/* <Skill /> */}
            {/* <Blog /> */}
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
