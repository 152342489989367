import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

const subTitle = "START TO SUCCESS";
const title = "Study in Germany.";

const achievementList = [
    {
        count: '17000',
        desc: 'Programs Available to Choose',
    },
    {
        count: '40000',
        desc: 'Average Income',
    },
    {
        count: '170000',
        desc: 'German Vacant Jobs',
    },
    {
        count: '1800',
        desc: 'Stay Back Visa',
    },
]

const Achievement = () => {
    const [inView, setInView] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setInView(true);
                    observer.disconnect(); // Stop observing after it enters view
                }
            },
            {
                threshold: 0.5, // Trigger when 50% of the section is visible
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className="achievement-section padding-tb" ref={sectionRef}>
            <div className="container">
                <div className="section-header text-center" data-aos="fade-right" data-aos-duration="1000">
                    <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="counter-part mb-4">
                        <div className="row g-4 row-cols-lg-4 row-cols-sm-2 row-cols-1 justify-content-center">
                            {achievementList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="count-item">
                                        <div className="count-inner">
                                            <div className="count-content">
                                                <h2>
                                                    <span className="count">
                                                        {inView && (
                                                            <CountUp start={0} end={parseInt(val.count)} duration={2} />
                                                        )}
                                                    </span>
                                                    <span>+</span>
                                                </h2>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Achievement;
