import React from 'react'
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
const title = "Looking for a student or job visa for Germany?";
const desc = "Well! Aspirations Inc. is here to assist you through the entire application of visa and immigration process. ";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const Visa_and_Immigration_Support = () => {
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/visa-and-imigration-support.jpg" alt="visa-and-imigration-support" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>  
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <h5>Your visa is getting on hold or rejected, and you cannot follow the specified and updated requirements.</h5>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">

                                            <section className="benefits">
                                                <h6>How Aspirations Inc. Help in Visa Assistance? </h6>
                                                <p>
                                                    Aspirations Inc. provides all-in-one assistance, which includes visa and immigration support. We have students, researchers and job seekers attain their type of visas hassle-free.
                                                    <br /><br />
                                                    We identify the type of program the student is seeking and track the admission progress after helping the student with the admission application process.
                                                    <br /><br />
                                                    Meanwhile, we also help students prepare for visa applications and their requirements.
                                                    <br /><br />
                                                    The prerequisite is often a lengthier process, and slight mistakes can lead to the cancellation of a visa, or it can be put on a long hold. Our expert consultants ensure the student's visa and immigration application are processed smoothly and mistake-free.
                                                    <br /><br />
                                                    You can entirely rely on our service while we take the Hustle!
                                                    <br /><br />
                                                    <h5 className='m-0'>How many types of German Visas are there? </h5>
                                                    <ul>
                                                        <li>Student visa </li>
                                                        <li>Job seeker visa </li>
                                                        <li>Language course visa </li>
                                                        <li>Student applicant visa </li>
                                                    </ul>
                                                    <h5 className='m-0'>How many types of German student visas are there?</h5>

                                                    Two types allow students to study in Germany. The Schengen visa (C visa) and the national visa (D visa) for courses lasting more than three months. <br /><br />
                                                    The Schengen visa (C visa) is provided for short classes lasting up to three months, and the national visa (D visa) is given for courses that last more than three months. <br /><br />
                                                    <h5 className='m-0'> Who can apply for a Visa?</h5>

                                                    Non-EEA and non-EU international students must hold a student visa to study in Germany. Visa is mandatory for Bachelor’s Degree, Master’s Program, PhD and Research opportunities, Vocational Training and Language programs. Visa is also compulsory for job seekers who either got a job or are seeking a career in Germany. Visa criteria vary from country to country, depending on the type of course you are willing to pursue and its duration.


                                                    <li>If you are looking for a short-term course lasting up to 90 days (3 months), you will require a Schengen (C Visa) Visa.</li>
                                                    <li>Suppose you have been studying for more than three months. If you have enrolled in a long-duration program, you will need a national visa (D Visa) and a resident permit once you arrive in Germany. </li>
                                                    <li>There are three German National Visa (D Visa) types: German student Visa, German Student Applicant Visa, and German language course visa.</li>
                                                    <br /><h5 className='m-0'>Visa Documents</h5>


                                                    The following are the visa documents mainly required for Indian students while applying for a Bachelor’s program in Germany:
                                                    <br />
                                                    <ul><li>Duly filled out and signed the national visa application form. </li>
                                                        <li>Two photocopies of your passport. </li>
                                                        <li>Birth certificate. </li>
                                                        <li>Valid passport. </li>
                                                        <li>Marriage certificate (if applicable). </li>
                                                        <li>Your child’s birth certificate (if applicable). </li>
                                                        <li>Recent passport-style photographs (up to 3). </li>
                                                        <li>Photocopies of previous German residence titles (if applicable).</li>
                                                    </ul>
                                                    For more details, you can consult our <a href="/register">expert consultants</a>.
                                                    <br /><br />
                                                    <h5 className='m-0'>When should you apply for a German student visa? </h5>
                                                    A good time to apply for a German student visa is when you receive the university or the educational institution’s acceptance letter. Also, one must apply for a German student visa after setting all the finances and all the documents clear. All the papers should be updated and original. One must prepare to meet all the criteria required for the visa and for University admission.
                                                    <br /><br />
                                                    Aspirations Inc. provide a comprehensive Visa Application service to Students, Learners, and Job Seekers! We follow one-on-one transparent consultation without any hidden charges.
                                                    <br /><br />
                                                    To learn more, check out our other services and <a href="/contact">connect with us</a> now!
                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
