import { Fragment, useEffect, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Archive from "../component/sidebar/archive";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import Instagram from "../component/sidebar/instagram";
import PopularPost from "../component/sidebar/popular-post";
import PostCategory from "../component/sidebar/post-category";
import Respond from "../component/sidebar/respond";
import Search from "../component/sidebar/search";
import Tags from "../component/sidebar/tags";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import * as mod from "./../url";


const socialList = [



    {
        link: 'https://www.facebook.com/profile.php?id=61555917730119',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: 'https://www.linkedin.com/company/aspirations-inc-global',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: 'https://www.instagram.com/aspirationsincglobal',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]
const BlogSingle = () => {
    const [blogs, setBlogs] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();
    const { slug } = useParams();
    // Retrieve stored data safely
    const fetchBlogs = async () => {
        try {
            const response = await axios.get(
                `${mod.api_url}/api/v1/blogs/single_blog/${slug}`
            );
            // console.log("Fetched blogs:", response.data);
            setBlogs(response?.data);
        } catch (error) {
            console.error("Error fetching blogs:", error);
            toast({
                title: "Error fetching blogs.",
                description: error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchBlogs()
    }, []);
    // console.log(blogs, 'blogs')
    const id = blogs?._id
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Interactively Morph High Standards Anding'} curPage={'Blog Detais'} />
            <div className="blog-section blog-single padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-12">
                            <article>
                                <div className="section-wrapper">
                                    <div className="row row-cols-1 justify-content-center g-4">
                                        <div className="col">
                                            <div className="post-item style-2">
                                                <div className="post-inner">
                                                    <div className="post-thumb">
                                                        <img src={
                                                            `${mod.api_url}/api/v1/blogs/singlePhoto/${id}` ||
                                                            "placeholder.jpg"
                                                        } />
                                                    </div>
                                                    <div className="post-content">
                                                        <h2>{blogs?.heading}</h2>

                                                        <div className="meta-post">
                                                            <ul className="lab-ul">
                                                                <li><Link to="#"><i className="icofont-calendar"></i>{blogs?.createdAt?.substring(0, 10)}</Link></li>
                                                                {/* <li><Link to="#"><i className="icofont-ui-user"></i>Rajib Raj</Link></li> */}
                                                                <li><Link to="#"><i className="icofont-speech-comments"></i>09 Comments</Link></li>
                                                            </ul>
                                                        </div>
                                                        {blogs?.subContents?.map((val, i) => (
                                                            <>
                                                                <h4>{val.subheading}</h4>
                                                                <p>{val.content}</p>
                                                            </>
                                                        ))}
                                                        {/* <blockquote>
                                                                <p>Dynamically recaptiualize distributed technologies is wherease turnkey channels and onotonectally provide access to resource leveling expertise vias worldwide deliverables uolisticly extend aserser are diverse vortals. </p>
                                                                <cite><Link to="#">...Melissa Hunter</Link></cite>
                                                            </blockquote> */}
                                                        {/* <img src="assets/images/blog/single/02.jpg" alt="rajibraj91" /> */}

                                                        {/* <div className="video-thumb">
                                                            <img src="assets/images/blog/single/03.jpg" alt="video" />
                                                            <Link to="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></Link>
                                                        </div> */}


                                                        <div className="tags-section">
                                                            <ul className="tags lab-ul">
                                                                <li><Link to="#">Agency</Link></li>
                                                                <li><Link to="#">Business</Link></li>
                                                                <li><Link to="#">Personal</Link></li>
                                                            </ul>
                                                            <ul className="lab-ul social-icons">
                                                                {socialList.map((val, i) => (
                                                                    <li key={i}>
                                                                        <a href={val.link} className={val.className} target="blank"><i className={val.iconName}></i></a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="navigations-part">
                                                <div className="left">
                                                    <Link to="#" className="prev"><i className="icofont-double-left"></i>Previous Article</Link>
                                                    <Link to="#" className="title">Evisculate Parallel Processes via Technica Sound Models Authoritative</Link>
                                                </div>
                                                <div className="right">
                                                    <Link to="#" className="prev">Next Article<i className="icofont-double-right"></i></Link>
                                                    <Link to="#" className="title">Qvisculate Parallel Processes via Technica Sound Models Authoritative</Link>
                                                </div>
                                            </div> */}
                                            {/* <Author /> */}
                                            {/* <Comment /> */}
                                            {/* <Respond /> */}
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-12">
                            <aside>
                                {/* <Search /> */}
                                {/* <PostCategory /> */}
                                <PopularPost />
                                {/* <Archive /> */}
                                {/* <Instagram /> */}
                                <Tags />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default BlogSingle;