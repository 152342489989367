import React from 'react'
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
const title = "OPPORTUNITY CARD aka CHANCENKARTE";
const desc = "On 1 June 2024, Germany introduced the opportunity card, also known as Chancenkarte, to simplify the immigration process for job seekers and skilled professionals from non-EU countries. With this initiative, international professionals, including Indian job seekers, can enter Germany and seek employment without any mandatory permanent job offer or family sponsorship. The opportunity card in Germany is provided to professionals on a points basis.";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const Pre_Departure_Orientation = () => {
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/pre-departure-orientation.jpg" alt="pre-departure-orientation" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">

                                            <section className="benefits">
                                                <h4>How Aspirations Inc. help job seekers throughout the
                                                    Opportunity Card (Chancenkarte) application process.</h4>
                                                <p>
                                                    Our pool of expert Consultants helps applicants obtain an opportunity card (Chancenkarte) with a 100% Success rate.
                                                    <br /><br />
                                                    <b>One-on-one mentorship:</b> Our mentorship, consultation, and guidance are personalised and transparent. We are updated on all the requirements, changes, and eligibility criteria; therefore, we help with the procedure without any mistakes!
                                                    <br /><br />

                                                    <b>Profiling:</b> We help to strengthen the profile of every candidate who wishes to grab a lucrative job offer in Germany through the Job Seeker Opportunity Card or Chancenkarte. We review all the academic background details, mark sheets, documents, achievements, and letters and suggest if there are any upgrades or upskills required to get the opportunity faster than other applicants.
                                                    <br /><br />

                                                    <b>Point Booster:</b> We help Candidates enter Germany with the maximum points achievable. We review all the documents and help make the candidates' profiles strong enough to quickly get the opportunity card (Chancenkarte).
                                                    <br /><br />
                                                    <b>Document Review:</b> Our team collects all the documents and papers required for the job search Opportunity Card, also known as Chancenkarte. We make sure to collect all the document and papers that needs to be presented during the application process.
                                                    <br /><br />
                                                    <b>Editing:</b> Our editors review, read, and edit the document wherever required and help translate all the documents into either English or German, as needed.
                                                    <br /><br />
                                                    <b>Reviewing application:</b> Our three-tier review ensures no stone is left unturned to meet the eligibility criteria.
                                                    <br /><br />
                                                    <b>Appointment:</b> We help to make an appointment at the German embassy with complete preparation of all the required documents and proof of criteria.
                                                    <br /><br />
                                                    <b>Application:</b> Our team of consultants always stay up-to-date with recent amendments in requirements, charges, eligibility, legal changes and other updates. We help students and professionals through the smooth application process for the Opportunity card (Chancenkarte) for Germany.
                                                    <br /><br />
                                                    <b>Fees:</b> We stay updated with all the fees, charges, payments, and breakdowns. Our team consults with the student beforehand regarding all the payment required and helps pay the fees for visa applications. We include no hidden charges!<br /><br />
                                                    <h5>We also help with post-arrival assistance, such as applications for a residence permit.</h5>
                                                    <h5>Bonus Services: </h5>
                                                    <ul>
                                                        <li>Aspirations Inc. Also help individual job seekers find their desired job in Germany</li>
                                                        <li>
                                                            We also help with accommodation assistance for your safe and comfort stay in Germany

                                                        </li>
                                                    </ul>
                                                    <h5>You can meet the Requirements for an Opportunity Card or Chancenkarte in two options: </h5>
                                                    <b>Option 1:</b> you must have studied and obtained your higher education (like a degree program) or received professional qualification in German universities or academic Institutions of Germany, or you must have a foreign professional or academic qualification acknowledged and recognised in Germany. You must also be proficient in English and German.
                                                    <br /><br />
                                                    <b>Or </b>
                                                    <br /><br />
                                                    <b>Option 2:</b> You have to score a minimum of 6 in the point system of opportunity card or Chancenkarte
                                                    <br /><br />
                                                    On the other hand, you must have successfully <b>completed vocational or academic training that is recognised</b> in Germany and your home country.
                                                    <br /><br />
                                                    The vocational qualification must have required at least two years of vocational training. To support your visa application, you must have a positive result for this qualification, which you can apply for at the <b>Central Office for Foreign Education (ZAB)</b>
                                                    <br /><br />
                                                    You must have German language skills of at least A1 Level, defined by the <b>Common European Framework of Reference for Languages (CEFR)</b>, and B2 Level English language proficiency (CEFR)
                                                    <br /><br />
                                                    <h5>The Point System of Opportunity Card (Chancenkarte) is briefed as follows: </h5>

                                                    <table className="table-auto w-full border-collapse border border-gray-400">
                                                        <thead>
                                                            <tr className="bg-gray-200">
                                                                <th className="border border-gray-400 px-4 py-2 text-left">Criteria</th>
                                                                <th className="border border-gray-400 px-4 py-2 text-left">Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="border border-gray-400 px-4 py-2">Qualification fully recognized in Germany</td>
                                                                <td className="border border-gray-400 px-4 py-2">4</td>
                                                            </tr>
                                                            <tr className="bg-gray-50">
                                                                <td className="border border-gray-400 px-4 py-2">Five years of work experience with B2 German language</td>
                                                                <td className="border border-gray-400 px-4 py-2">3</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border border-gray-400 px-4 py-2">Two years of work experience with B1 German, under 35 years old</td>
                                                                <td className="border border-gray-400 px-4 py-2">2</td>
                                                            </tr>
                                                            <tr className="bg-gray-50">
                                                                <td className="border border-gray-400 px-4 py-2">Previous stay in Germany (non-tourist)</td>
                                                                <td className="border border-gray-400 px-4 py-2">1</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border border-gray-400 px-4 py-2">Qualification in a bottleneck profession</td>
                                                                <td className="border border-gray-400 px-4 py-2">1</td>
                                                            </tr>
                                                            <tr className="bg-gray-50">
                                                                <td className="border border-gray-400 px-4 py-2">A2 German proficiency or C1 English Proficiency</td>
                                                                <td className="border border-gray-400 px-4 py-2">1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br /><br />
                                                    However, many other terms and requirements exist to achieve maximum points, be eligible to attain the Opportunity Card (Chancenkarte) and get a job in Germany.
                                                    <br /><br />
                                                    <a href="/register">Maximise your Chances of Getting Higher Points with Aspirations Inc.</a> ASAP!
                                                    <br /><br />
                                                    Aspirations Inc. is happy to assist you through the application and gaining the Opportunity Card (Chancenkarte).  For more details and a one-on-one consultation, <a href="/contact">Book a Consultation now!</a>

                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
