import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import * as mod from "./../../url";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const useTitle = "Quick Links";
const socialTitle = "Social Contact";
const servicesTitle = "Our Services";


const siteList = [

    {
        text: 'info.education@aspirations.global',
        link: 'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox/FMfcgzQVwnRsFksCcKwqLXBMzXvvVQrS?compose=new',
    },
    {
        text: '+91 93100 72343',
        link: '#',
    },
    {
        text: '',
        link: '#',
    },

]

const useList = [
    {
        text: 'About Us',
        link: '/about',
    },
    {
        text: 'Contact Us',
        link: '/contact',
    },

    {
        text: 'Privacy Policy',
        link: '/privacy-policy',
    },


]

const socialList = [
    {
        text: 'Facebook',
        link: 'https://www.facebook.com/profile.php?id=61555917730119',
    },
    {
        text: 'Instagram',
        link: 'https://www.instagram.com/aspirationsincglobal/',
    },


    {
        text: 'Linkedin',
        link: 'https://www.linkedin.com/company/aspirations-inc-global/',
    },
]

const servicesList = [
    {
        text: 'Application Assistance',
        link: "/aplication-assistance",
    },
    {
        text: 'APS Verification',
        link: '/APS-verification',
    },
    {
        text: 'Visa And Immigration Support',
        link: '/visa-and-immigration-support',
    },
    {
        text: 'Opportunity Card/Chancenkarte',
        link: '/Opportunity-Card-Chancenkarte',
    },
    {
        text: "Germany Job Seeker Visa",
        link: "/Germany-Job-Seeker-Visa",
    },


]

const Footer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${mod.api_url}/api/v1/emails/submit_details`, {
                email,
            });

            // Check if the request was successful
            if (response.status === 201) {
                setMessage('Subscription successful!');
                setEmail('');
            } else {
                setMessage('Subscription failed: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div className="news-footer-wrap" style={{ background: '#939799', color: "black" }}>
            <div className="fs-shape">
                {/* <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" /> */}
                {/* <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" /> */}
            </div>

            <div className="news-letter">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="news-title">
                            <h3>{newsTitle}</h3>
                        </div>
                        <div className="news-form" style={{ color: "gray" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="nf-list">
                                    <input type="email" name="email" placeholder="Enter Your Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required />
                                    <input type="submit" name="submit" value="Subscribe Now" className="" style={{ background: 'gray' }} />
                                </div>
                            </form>
                            {message && <p>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer-top padding-tb pt-0">
                    <div className="container">
                        <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <img src="assets/images/logo/01.png" alt="fst" className="fst-2" style={{ background: 'white' }} />
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {siteList.map((val, i) => (
                                                        <li key={i}><Link to={val.link} target="blank">{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{useTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {useList.map((val, i) => (
                                                        <li key={i}><Link to={val.link}>{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{socialTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {socialList.map((val, i) => (
                                                        <li key={i}><a href={val.link} target="blank">{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{servicesTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {servicesList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom style-2">
                    <div className="container">
                        <div className="section-wrapper">
                            <p>&copy;  2025 All rights reserved </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;