import React, { useState } from 'react';
// import './PhDPage.css';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
const title = "PhD in Germany";
const desc = "Studying PhD in Germany as an international student gives you a lot of perks in both academic and research opportunities! Students get to engage with the world's top-class Research and education system. Doctorates enjoy a remarkable reputation in Germany. Around 5800 International research Scholars are awarded their Doctorate by universities in Germany.";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const PHD_Degree_In_Germany = () => {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const toggleFAQ1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleFAQ2 = () => {
        setIsOpen2(!isOpen2);
    };
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/phd-in-germoney.jpg" alt="phd-in-germoney" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <div className="phd-page">

                                                <section className="why-germany">
                                                    <h4>Why do International students choose to pursue PhD in Germany? </h4>
                                                    <ul>
                                                        <li><strong>Excellent research institutions:</strong> There are more than 1000 publicly funded research institutions in Germany that provide superior-class research options with top-notch facilities. Universities, Research Institutes, government bodies, universities of Applied Sciences, and industry-sector licensed companies can offer PhD degrees to German and international students. </li><br />
                                                        <li><strong>High investment in research:</strong> with the government aiming to increase its spending to 3.5% of the country's GDP by 2025. As per the latest reports, Germany is already spending over 3% of its economic output on Research and Development, making it one of the leading countries in Europe in terms of R&D.</li>
                                                        <li><strong>Diverse Research Landscape:</strong> Germany creates a healthy and flourishing space for Research and Development, which attracts international students to come and pursue a PhD. The country has a remarkable research infrastructure. Facilities are well-equipped for conducting study, research and tests. The country spends around € 32.2 billion on Research and Development annually and welcomes a wide range of disciplines and career opportunities for German and International researchers and PhD students. </li>
                                                        <li><strong>Best Opportunities at Low Cost:</strong> The ease of living, the culture, the academic system, the R&D system – everything you name it is availed to the students at their highest standard, whereas the cost of education is pocket-friendly!

                                                        </li>
                                                        Strong economy: Germany is known for its stable and strong economic foothold, which helps employment, business, startups and more research opportunities.
                                                    </ul>
                                                </section>

                                                <section className="types-of-phd">
                                                    <h4>Types of PhD Offered in Germany</h4>
                                                    <div className="phd-types row">
                                                        <div className="phd-type col-md-6">
                                                            <h4>1.Individual PhD Degree</h4>
                                                            <p>In Germany, the individual PhD Degree is a very common form of undergoing a PhD program. This is where a student has to find their supervisor (Doktorvater/Doktomutter) and propose the research topic the student desires to study. Hence, the student is expected to take charge of the work. The individual PhD programs are especially seen in the Humanities and Social Sciences department. These PhD programs offer flexibility with no fixed curriculum, allowing students ample time to develop their research and choose their coursework. In these programs, the student must work on a dissertation thesis. Mainly accredited universities and licensed companies provide individual PhD degrees in Germany. </p>
                                                        </div>
                                                        <div className="phd-type col-md-6" >
                                                            <h4>2.Structured PhD Degree</h4>
                                                            <p>In the structured PhD degree, a small group of PhD students make a joint PhD project on a specific topic. This structured PhD program usually takes around 5 years to complete the PhD journey. The Structured PhD programs in Germany are mainly instructed in the English language. This PhD program is called so because it is structured in a way that it contains a specified curriculum along with a research proposal which has to fit in this project. This program also has a specified deadline for the research work. This kind of PhD program is supervised by an advisor or by a group of experts who mentor and evaluate the progress of these PhD students. </p>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section className="how-to-apply">
                                                    <h4>How to Apply for PhD in Germany</h4>
                                                    <p>
                                                        There are two types of PhD programs: Individual PhD and Structured PhD. Follow these steps to apply:
                                                    </p>
                                                    <b>Identify</b> the type of program you want to do in Germany. The individual PhD program is the traditional way to your PhD journey. A thesis or dissertation must be produced under the supervision of your supervisor. So, your first step is to find a professor who can be your suitable supervisor in this PhD program.
                                                    <br /><br />

                                                    <b> Consider</b> your academic background and your desired area of interest, and then do detailed research accordingly for the subject in which you want to pursue a PhD. You can find a suitable supervisor from the university or academic institute that matches your area of interest in research.
                                                </section>

                                                <section className="documents-required">
                                                    <h4>The following can help you find your research interest, the PhD programs and Research institutions:</h4>
                                                    <b>1. The DAAD PhD Database:</b> The German Academic Exchange Service (DAAD) has an endless list of PhD and Research Opportunities
                                                    <br /><br />

                                                    <b>2. GERiT Database:</b> There are more than 31000 research programs listed in GERiT - German Research Institutions. You can filter and shortlist PhD programs by selecting the subject group, subject area, Institution and town to find their suitable interest.
                                                    <br />
                                                    <b>3. Manual search:</b> You can also find your area of interest the subject the university the professor and every detail manually on the internet. You can also find the professor List on the Universities that have your subject of interest.
                                                    <br /><br />
                                                    <b>4. Other popular online platforms are:</b>

                                                    <ul>
                                                        <br />
                                                        <li>academics.de </li>
                                                        <li>PhD Germany</li>
                                                        <li>Hochschulkompass.de .</li>
                                                        <li>www.higher-education-compass..de </li>
                                                    </ul>
                                                </section>

                                                <section className="visa-requirements">
                                                    <h4>How to apply for your Doctoral Studies in Germany </h4>
                                                    <p>After detailed research of your suitable PhD program and selecting your supervisor and the subject, check on your academic qualifications and requirements that are required for the Visa and immigration, and PhD research application. You must keep all the documents ready, that are required to submit your application. The details of the requirement are usually provided by the university or the academic institution. You must also contact your PhD supervisor for details.
                                                        <br />
                                                        <br />
                                                        The application can be sent online or by post, which depends on the university's choices. It is advised to check all the guidelines and specifications required during the whole process. The admission committee will decide if you are academic profile and your criteria match with the university's PhD program. In some cases, they might also conduct an interview. </p>
                                                    <br />
                                                    <p>The committee in charge generally asks to show proof of the following: </p>
                                                    <ul>
                                                        <li><b>Research proposal -</b> The student must prepare and design a detailed research proposal. This research proposal must include the title of the topic, abstract, aims and objectives, methodology and significance </li>

                                                        <li><b> Statement of purpose (SOP) -</b> The statement of purpose must include your academic goals, your career goals and why you want to do a PhD in your desired subject</li>


                                                        <li><b>CV or Resume –</b> An updated CV or Resume with all your academic qualifications, achievements, research experience and coursework (if applicable)</li>
                                                        <li><b>Academy Degree that is recognised in Germany –</b> Master’s degree is mainly required for PhD or have a German State examination (Staatsexamen) in the relevant field of your research </li>
                                                        <li><b>Copies of all the academic qualifications – </b>From school till Master’s degree mainly is required</li>
                                                        <li><b>Proof of Language Proficiency -</b> It depends upon the program criteria. Mainly, PhD is conducted in the English language, but sometimes it's conducted in German language, and often, the instruction is offered in a blend of both languages. So most Universities and academic Institutions prefer proof of English language proficiency and German language proficiency. Language Proficiency in the English Language from TOEFL and IELTS and Language Proficiency in the German Language from DSH and TestDaF are mainly required. For the Indian students, learning German Language from Goethe Institute are recognized in German Universities</li>
                                                        <li><b>Copy of Master thesis - </b>Students must submit a copy of their master thesis. This helps the supervisor evaluate the student’s research skills, analytical skills, problem-solving skills, writing skills and academic work

                                                        </li>
                                                        <li><b>Pre-doctoral examination -</b> Students need to pass a predoctoral examination in some PhD programs</li>
                                                        <li><b>Statement from your supervisor –</b> The Supervisor’s agreement to mentor your PhD, including the mentioning of research highlights, Plan of support, added value, and approach of supervision might be required during the application process</li>

                                                        <li><b>Academic references - </b>Recommendation letters, along with the attestation to prove your academic abilities, achievements and excellence, from academic advisors, or professors can be required as a part of the application process</li>
                                                        <li><b>Visa –</b> Depending on the place of immigration and your PhD program, you might require any of the following:</li>
                                                        <ul>
                                                            <li>Study Visa</li>
                                                            <li>Research Visa</li>
                                                            <li> EU Blue Card</li>
                                                        </ul>
                                                        <li>        <b>Secure Funding –</b> Proof of financial stability of self, and/or parents or anyone from Germany sponsoring the student. The funding should be able to cover a student’s study cost and living costs. The student can also show any Scholarship Program that supports the student’s overall cost</li>
                                                        <li>   <b>Health Insurance –</b> In some applications a student’s Health insurance coverage may be asked to show
                                                        </li>
                                                        <li><b>Blocked Account –</b> A blocked account certificate</li>

                                                    </ul>
                                                </section>
                                                <section className="visa-requirements">
                                                    <h4>Visa Requirements</h4>
                                                    <p>Indian students can get the following Visa, depending upon the program of study, duration of stay and various other situations </p>
                                                    <ol>
                                                        <li>
                                                            Study Visa – A Study Visa is required if the student is pursuing a full-time PhD program
                                                        </li>
                                                        <li>
                                                            Research Visa – A Research Visa is applicable when the student has a formal affiliation with the university that is providing the in Germany and when the student is focused on research and development

                                                        </li>
                                                        <li>
                                                            EU Blue Card - A student who is availing the PhD which offers a minimum salary of € 45300 per year then the student can apply for an EU blue card

                                                        </li>
                                                    </ol>
                                                    <p>It is recommended to start the process of German Student Visa application and set up a visa interview at the German Embassy or Consulate in the home country before admission. The student is required to present all the documents and valid proofs during the Visa interview process.
                                                        <br /><br />
                                                        The following are the visa documents mainly required for Indian students while applying for a PhD Program in Germany:</p>
                                                    <ul>
                                                        <li>
                                                            Duly filled out and signed national visa application form.
                                                        </li>
                                                        <li>Two photocopies of your passport. </li>
                                                        <li>Birth certificate.</li>
                                                        <li>Valid passport. </li>
                                                        <li>Marriage certificate (if applicable). </li>
                                                        <li>Your child’s birth certificate (if applicable).</li>
                                                        <li>Recent passport-style photographs (up to 3).</li>
                                                        <li>Proof of mentor acceptance to supervise your doctoral thesis work</li>
                                                        <li>Health Insurance Proof </li>
                                                        <li>Blocked Account </li>
                                                        <li>
                                                            Photocopies of previous German residence titles (if applicable).</li>
                                                    </ul>
                                                </section>

                                                <section className="resident-permit">
                                                    <h4>Resident Permit</h4>
                                                    <p>If you are an Indian student then you have to apply for a Resident permit to stay in Germany during your PhD program, and you need to apply within three months of arrival.
                                                        <br /><br />
                                                        There are three types of Resident Permit: </p>
                                                    <ul>
                                                        <li><b>Study Permit –</b> the study-based resident permit lasts up to 2 years, and this Resident Permit can be extendable. You are allowed to avail of a study permit if you get a chance into the PhD program in a German university </li>
                                                        <li><b>Research Permit –</b>  In a Research Permit, the student or researcher has to make a contract with the research institution or the university that is providing the research program. The researcher with qualified requirements for PhD or doctoral program can apply for a research Permit </li>
                                                        <li><b>EU Blue Card – </b> the PhD program that salary of per year can be eligible for the EU Blue Card

                                                            - a student can apply for any of the above permits </li>
                                                    </ul>
                                                </section>

                                                <section className="health-insurance">
                                                    <h4>Health Insurance</h4>
                                                    <p>Important things to note: </p>
                                                    <ol>
                                                        <li>
                                                            PhD or doctoral candidates with an employment contract have a high chance of getting insured with a state-regulated health insurance provider (Gesetzliche Krankenversicherung-GKV)

                                                        </li>
                                                        <li>
                                                            Whereas, PhD or Doctoral candidates without an employment contract (with a fellowship or private funding) can choose between:

                                                        </li>
                                                    </ol>
                                                    <ul>
                                                        <li>Voluntary health insurance coverage with a state-regulated provider.</li>
                                                        <li>
                                                            Coverage with a private health insurance company.
                                                        </li>

                                                    </ul>
                                                    <p>Health Insurance is mandatory for all international students, which includes the Indian students who are applying for PhD programs or doctoral research in Germany. </p>
                                                    <ul>
                                                        <li>
                                                            It is recommended that you start applying for the health insurance process before getting admitted to any German university.
                                                        </li>
                                                        <li>
                                                            In most cases, Private Health Insurance from other countries can also be taken into consideration.

                                                        </li>
                                                    </ul>
                                                    <p>Students can also choose to obtain Health Insurance through a provider in Germany  </p>
                                                </section>

                                                <section className="language-proficiency">
                                                    <h4>Language Criteria for PhD and Doctoral Studies</h4>
                                                    <p><b>Language Proficiency:</b> Language proficiency in German and English is most important for pursuing PhD or Doctoral studies in Germany. Applicants for PhD degrees are often required to undergo language proficiency tests. </p>
                                                    <p><b>Proficiency level:</b> The proficiency level in German and English depends on the complexity of the research, the criteria set by the university providing a PhD, and the language of mentorship. Hence, the language proficiency level can be between B1 and C1 levels. Language efficiency and fluency higher than C1 Level may also be required in professions like technical and healthcare.</p>
                                                    <p>
                                                        <b> Language tests:</b> Most educational institutions, Universities, Employers, and Vocational training providers mainly accept the language certification from widely recognised bodies like TestDaF, DSH, and Goethe-Zertifikat. Indian students must learn German and pass the German language test from Goethe Institute, as it's the only institute from India acknowledged in Germany.
                                                    </p>

                                                </section>

                                                <section className="faqs">
                                                    <h4>FAQs</h4>

                                                    <div className="faq-item">
                                                        <h6 onClick={toggleFAQ1} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {isOpen1 ? <FaChevronDown /> : <FaChevronRight />} &nbsp;
                                                            How long does it take to get a PhD in Germany?
                                                        </h6>
                                                        {isOpen1 && (
                                                            <p>
                                                                It takes nearly three to six years to complete a PhD in Germany. It can take a bit
                                                                longer or shorter than the mentioned time frame, depending on the program’s criteria.
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="faq-item">
                                                        <h6 onClick={toggleFAQ2} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                            {isOpen2 ? <FaChevronDown /> : <FaChevronRight />} &nbsp;
                                                            Is learning German mandatory?
                                                        </h6>
                                                        {isOpen2 && (
                                                            <p>
                                                                It depends upon the program criteria. Mainly PhD is conducted in the English language
                                                                but sometimes it's conducted in German and often the instruction is offered in a blend
                                                                of both languages. Most Universities and academic Institutions prefer proof of both
                                                                English and German language proficiency. Proficiency in English (via TOEFL, IELTS) and
                                                                in German (via DSH, TestDaF) is typically required. For Indian students, learning
                                                                German at the Goethe Institute is recognized by German Universities.
                                                            </p>
                                                        )}
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}
