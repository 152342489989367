
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const title = "Why Should you Study in Germany?";
const subTitle = "While searching for studying abroad, one might have a hard time choosing the right country for pursuing education in the first place! But if you look at the current data, it’s Germany where most of the international students pursue the best quality of education. Undoubtedly, Germany has been one of the best countries to pursue higher education on various domains. ";

const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/01.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: '',
        degi: '',
        desc: 'While searching for studying abroad, one might have a hard time choosing the right country for pursuing education in the first place! But if you look at the current data, it’s Germany where most of the international students pursue the best quality of education. Undoubtedly, Germany has been one of the best countries to pursue higher education on various domains. As an Indian student, you can pursue to study in Germany after class 12th standard, or after graduation. Or you are most welcome to pursue even higher studies like PhD and do your Research here! Germany has been constantly recommended by alumni, international students and our Indian residents, as the best country to pursue technical, non-technical, Bachelor’s, Masters, PhD, Research and other academic programmes altogether! ',
    },

]


const Student = () => {
    return (
        <div className="student-feedbak-sections shape-img">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle" style={{ color: 'gray' }}>{subTitle}</span> */}
                    <h2 className="title">{title}</h2>
                    {/* <hr /> */}
                    <p data-aos="fade-right" data-aos-duration="1000">While searching for studying abroad, one might have a hard time choosing the right country for pursuing education in the first place! But if you look at the current data, it’s Germany where most of the international students pursue the best quality of education. Undoubtedly, Germany has been one of the best countries to pursue higher education on various domains.</p>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="" data-aos="fade-left" data-aos-duration="1000">
                                    <img src="assets/images/feedback/01.jpg" alt="student feedback" />
                                    {/* <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/wNadwJanpy8?si=f5S77x1U8RjAH6qy"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe> */}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="">
                                            {/* <div className="sft-left"> */}
                                            {/* <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div> */}
                                            {/* <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div> */}
                                            {/* </div> */}
                                            {/* <div className="sft-right">
                                                <Rating />
                                            </div> */}
                                        </div>
                                        <div className="stu-feed-bottom" data-aos="fade-right" data-aos-duration="1000">
                                            <><span> As an <b>Indian student, you can pursue to study in Germany after class 12th standard, or after graduation.</b> Or you are most welcome to pursue even higher studies like PhD and do your Research here! Germany has been constantly recommended by alumni, international students and our Indian residents, as the best country to pursue technical, non-technical, Bachelor’s, Masters, PhD, Research and other academic programmes altogether!</span><br /></><span><b>There are numerous valid reasons why you should study in Germany as an Indian student:</b> </span><span>Let’s start with the numbers!<br />
                                                There are more than 420+ universities in Germany. Almost 20 universities of Germany ranks among the top 200 universities in the world.<br />
                                                423+ higher education institutes<br />
                                                108 universities<br />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
                <h4>This also includes:</h4>
                <>
                    <ul data-aos="fade-right" data-aos-duration="1000">
                        <li>211 universities of applied sciences</li>
                        <li>52 colleges of Art and Music</li>
                        <li>30 colleges of public administration </li>
                        <li>16 theological universities</li>
                        <li>6 colleges of education</li>
                        <li>More than 1+ million students are enrolled for STEM subjects in Germany</li>
                        <li>750000+ students are enrolled in engineering subjects</li>

                    </ul>
                    <p data-aos="fade-right" data-aos-duration="1000">This proves the <b style={{ color: "black" }}>academic excellence</b> that the country holds and the reason why it attracts a lot of international students<br />
                        The mix of modern and classics; with the equal interest in art, science, economy and technology is what makes the quality of education to be top-notch here. <b style={{ color: "black" }}>German education system</b> not only has given us some of the world’s top ranked universities, but it is also known for its vast number of courses and globally acknowledged degrees. There are two types of Universities in Germany: i) Public universities and ii) Private universities. The education imparted in both types of universities are ahead of the curve!
                        <br />

                        <br /><b style={{ color: "black" }}>Free of cost Public universities </b><br />
                        Most of the public universities offer free or minimal cost for education. Students mainly have to pay only the administrative cost and achieve exceptional education from German public universities. Hence many international students including Indian students opt for studying in Germany. Like India, the competition is tough and seats are limited. Private universities on the other hand provides top-grade academic programmes, most of which are in English language. <b style={{ color: "black" }}>But the cost of study</b> in <b style={{ color: "black" }}>private colleges of Germany</b> can come up with a significant financial investment; rewarding fruitful opportunities of internships, master thesis, job employment.
                        <br />
                        <br />
                        <h6>Students can avail Scholarship opportunities</h6>
                        There are plenty of scholarships that are available from private organisations and DAAD, which are designed mainly for International students. Hence Indian students can also apply for scholarship to ease down their finances
                    </p>

                </>
            </div>
        </div>
    );
}

export default Student;