
import React from 'react'
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
const title = "APS Verification Process ";
const desc = "The APS certificate is issued by the Academic Evaluation Centre (APS), also known as Akademische Prüfstelle, for Indian students who want to study in Germany. This certificate is essential to get a Visa and admission to German Universities and Educational Institutions. The APS certificate is a proof of authenticity of all the academic documents, students' degrees and eligibility. This Certificate ensures your academic qualifications’ authenticity and equivalence. The APS certificate is now mandatory for any Indian student who wants to pursue higher studies, such as a Bachelor's Degree program, master's degree program, PhD, vocational training, etc, in Germany. ";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const APS_verification = () => {
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/personalised-consultantion.jpg" alt="rajibraj91" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">

                                            <section className="benefits">
                                                <h4>What the APS certificate signifies : </h4>
                                                <p>
                                                    <b>Alignment of equivalence assessment:</b> The German and Indian education systems are different. Hence, the German education system has set its standards and expectations. Here, the APS certificate plays a vital role. This APS certificate assesses whether the Indian students’ academic qualifications, scores, percentage (or CGPA), and other criteria in the respective Indian school/college or educational institutions align with the German education system. This assessment ensures that the student meets the entry-level requirements for admission and the visa required for the chosen program.
                                                    <br /><br />
                                                    <b>Validation of education:</b> The APS certification is the validity proof of academic qualifications and the authenticity of all mark sheets, transcripts, diploma certificates, and documents. The APS thoroughly examines and scrutinises all the documents, mark sheets, and academic qualifications alongside the student's educational journey to ensure the student's legitimacy.
                                                    <br /><br />
                                                    <b>Visa application:</b> The APS certificate has become a vital part of the German student visa application process for Indian students. Without the APS certificate, the Visa application will be incomplete and may face delay or rejection. <br /><br />
                                                    <b>Bridging the gap:</b> The APS certificate bridges the gap between the Indian and German educational systems. It is necessary to equip Indian students’ academic eligibility with the German university education criteria for higher studies and programs.
                                                    <br /><br />
                                                    <h4 className='m-0'>How Aspirations Inc. help students to get APS certification the correct way:</h4>

                                                    The APS verification process may seem easy, but if it is not followed correctly, the application can be put on hold or rejected. <br />
                                                    Aspirations Inc. assists students and their parents or guardians with the APS Verification process. If you are a student or parent looking for adequate assistance with APS Verification and Certificate issuance, you have scrolled through the correct page! We will work with you and other Indian students in a detailed and diligent manner.<br />
                                                    Our professional consultants start by understanding your academic portfolio and the desired choice of study and begin consultation regarding the APS verification process accordingly. <br />
                                                    At Aspirations Inc., we provide assistance to ensure that you and your parents are well-informed about the requirements and criteria throughout the verification process. We stay up-to-date with the requirements and eligibility for APS Verification and guide you accordingly. Next, we help collect the required documents and ensure the authenticity of the up-to-date data that has been provided. <br />
                                                    Our team also ensure a safe and cautious transfer of the APS verification fee to the APS India bank account. Once the application has been submitted, APS India will start with the verification process. We keep track of the verification progress and ensure the certificates have been processed on time. After successful evaluation, APS India sends the original and official APS certificate directly to the student by courier. We will re-check the certificate and proceed to our immediate services.<br />
                                                    <br />
                                                    <h4 className='m-0'>A quick answer to Who Are We?</h4>
                                                    <b>Aspirations Inc.</b> is a German Education Consultancy in India that thoroughly assists aspiring students in studying in Germany at the best German Universities and Academic Institutions. Our team of expert consultants are all qualified professionals from some of the top German Universities. We are working closely with public and private German universities and have collaborative partnerships and strategic alliances with some of the top German universities. Aspirations Inc. is the foundation for students seeking to study in Germany. We assist students in getting hassle-free admission and other associated support to get into their desired Universities with their preferred subjects.
                                                    <h4 className='m-0'> The APS application process</h4>
                                                    <b>
                                                        Here’s a short overview of how the APS Verification process works. All you need to do is co-operate with our Team, and they will undergo the following process: <br />

                                                        [Note: The following process is a brief and prolonged procedure. The requirements, process, and eligibility criteria are subject to change as per the APS India guidelines. Students are recommended to check on the APS India official website and also stay connected with the Aspirations Inc. helpline and assistance for the latest updates]
                                                    </b>
                                                    <br /><br />

                                                    <b>Understand the requirement:</b> The APS India official website has detailed information on APS Certification. The APS certificate is mandatory for Indian students applying for Bachelor's, Master’s, Vocational and PhD programs in Germany. However, there are exceptions for those who have received German or EU-funded scholarships, PhD applicants whose supervisors do not require it, and students attending short-term courses (less than 90 days). Students need to understand the requirements and prepare all the documents accordingly.<br /><br />
                                                    <b>Collect the documents:</b> For the document verification process, students must collect and submit the documents prescribed by APS India. Mainly, the following documents are required for the APS verification process:<br /><br />
                                                    <b>Documents required for APS Verification:</b>


                                                    <br />
                                                    <ol>
                                                        <li>
                                                            Completed application form
                                                        </li>
                                                        <li>
                                                            Recent passport-sized photograph
                                                        </li>
                                                        <li>
                                                            Updated Aadhaar Card
                                                        </li>
                                                        <li>
                                                            Passport photocopy (first and last page)
                                                        </li>
                                                        <li>
                                                            Payment verification receipt
                                                        </li>
                                                        <li>
                                                            Academic documents
                                                        </li>
                                                        <li>
                                                            School leaving certificate
                                                        </li>

                                                        <li>The grading system used by your secondary and higher secondary school</li>
                                                        <li>University diploma certificates (if applicable)</li>
                                                        <li>Language proficiency Certificate (if it is applicable) </li>
                                                        <li>Additional Documents </li>
                                                    </ol>
                                                    <h6>The students need to notarise documents translated into English if the documents are not in English. </h6>
                                                    <b>Prepare for APS application:</b> The students need to prepare for the APS application after they have gathered all the documents. The guidelines are provided by the APS India office on their official website. It is essential to fill out the application form carefully.
                                                    <br /><br />
                                                    <b>Submission of the APS application:</b> After collecting the correct and up-to-date information from the official APS India website, the students have to register for the APS India website and download the application. If asked, the application fee has to be submitted. After downloading the application form, the students must complete the details electronically. Then, the students must print the completed form and attach copies of all the documents required by the APS India checklist. The APS office in India accepts documents by letter, post, or courier.
                                                    <br /><br />
                                                    <b>Process and progress:</b> After submitting the APS application, it undergoes the verification process. The timeline can be as long as 3 to 4 weeks, but it can take a long time. Therefore, students need to prepare and plan accordingly for the application. The students can use their logging credentials to track the APS application's progress.
                                                    <br /><br />
                                                    <b>Receiving the APS certificate:</b> After the verification is complete, APS India will send the original and official APS certificate directly to the student via courier
                                                    <br /><br />
                                                    <h6>Please note that it is essential to thoroughly review the APS verification criteria and application process on the ABS India official website.</h6>

                                                    <b>Aspirations Inc. assists you through the complete process of APS Verification so that you can focus on preparing to study in Germany without worrying about all the lengthy processes. Our extended and comprehensive services cover all the procedures of: <br /> <br />
                                                        Admission, Visa and Immigration, Accommodation, Opportunity Cards, and Financial requirements such as Education Loans, Blocked accounts, Language Proficiency Verification, Studienkolleg assistance and much more…. <br /> <br />

                                                        <a href="/register"><i>Connect with Aspirations Inc.</i></a> for a detailed Admission Consultation and prepare for your academic journey.
                                                        <br /> <br />
                                                        Be a hundred steps ahead from now on! </b>

                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
