import React from 'react'
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
const title = "Germany Job Seeker Visa for Indians";
const desc = "If you are a job seeker looking for a job abroad, especially in Germany, - then we’ve got your back!";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";
export const Personalised_Consultation = () => {
    return (
        <>
            <Header />
            <div className="pageheader-section style-2">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/final/personalised-consultantion.jpg" alt="rajibraj91" className="w-100" />
                                {/* <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-content">
                                <h4 className="phs-title">{title}</h4>
                                <p className="phs-desc">{desc}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">

                                            <section className="benefits">
                                                {/* <h2>Germany Job Seeker Visa for Indians </h2> */}
                                                <p>
                                                    Germany, the world's third-largest economy, has several job vacancies for international students and job seekers. The <b>German job seeker visa</b> allows an Indian applicant to enter Germany's job market and helps grow one's career aspirations rapidly.
                                                    <br /><br />
                                                    Aspirations Inc. helps you attain this job seeker’s visa! Our industry expert consultants, who have pedigrees from German and other international universities and have worked in Germany, have guided thousands of students and job seekers to attain full admission support and job assistance respectively!
                                                </p>
                                                    <h4 className='m-0'>The German Job Seeker Visa Scheme</h4>
                                                    The German job seeker visa scheme allows people from non-EU countries to actively seek employment in Germany for a specified period. For example, a job seeker can actively search for employment for six months.
                                                    <br /><br />
                                                    Indian job seekers can also apply for a German job seeker visa scheme to explore the German job market first-hand during the specified time. They can connect with networks of professionals and potential employers, apply for jobs, and attend interviews.<br /><br />
                                                    <h4 className='m-0'>How Aspiration Inc. help you attend a German Job Seeker Visa </h4>
                                                    Aspirations Inc. evaluates your profile strength and helps create a roadmap to the entire process journey!
                                                    <br /><br />
                                                    <b>One-on-one consultation:</b> Every consultation we conduct with our clients is personalised based on the qualification, eligibility criteria, type of job the person is seeking, and much more. Our consultation is fully transparent, with no hidden charges.
                                                    <br /><br />
                                                    <b>Document review:</b> We ensure the job seeker has all the documents ready for the Visa and the job requirements. The papers should be original, authentic, and updated, and we help translate the document into English or German as required. Our three-tier review of the documents and vital papers ensures error-free application!
                                                    <br /><br />
                                                    <b>Writing and Editing:</b> Our expert writers help frame the CV or resume, cover letter, statement of purpose, letter of recommendation, and all the other required documents. We edit and proofread before submission.
                                                    <br /><br />
                                                    <b>Visa Process:</b> We help our clients with the complete visa process, which includes:
                                                    <br /><br />
                                                    <ul>
                                                        <li>
                                                            Filling out the application form,
                                                        </li>
                                                        <li>
                                                            Booking and Employment with the German Embassy or consulate,
                                                        </li>
                                                        <li>
                                                            Preparing all the required documents,
                                                        </li>
                                                        <li>
                                                            Paying the fees,
                                                        </li>
                                                        <li>
                                                            Submitting the application,
                                                        </li>
                                                        <li>
                                                            Tracking the visa progress and
                                                        </li>
                                                        <li>
                                                            Making sure the visa is attained on time
                                                        </li></ul>

                                                    <b>Post-job assistance:</b> After securing an employment opportunity in Germany while on a job seeker visa, we help the applicant to get an EU-Blue Card. With this EU-Blue card, the applicant can also apply for permanent residence. <br /><br />

                                                    <b>Employment residence permit:</b> If the client wants, we help to apply for an employment residence permit
                                                    <br /><br />
                                                    <b>Accommodation assistance:</b> Our accommodation assistance is not limited to students; we also help job seekers looking for accommodation facilities. Our extended services include meeting the prerequisites for accommodation in Germany after securing admission to a University or a job opportunity.  <br /><br />

                                                    <h5 className='m-0'>What are the costs involved in attaining a German job seeker visa?</h5>

                                                    The total cost of obtaining a job seeker visa in Germany may vary because the requirements and prices change from time to time. Applicants must keep up with the current overall estimates.  <br /><br />
                                                    We can help you calculate the total cost of a German job seeker visa. We include the cost of:
                                                    <ul>
                                                        <li>
                                                            The visa application fee,
                                                        </li>
                                                        <li>
                                                            Travel health insurance,
                                                        </li>
                                                        <li>
                                                            ZAB Qualification Assessment,
                                                        </li>
                                                        <li>
                                                            Flight ticket,
                                                        </li>
                                                        <li>
                                                            Document verification (depending on the region)
                                                        </li>
                                                        <li>
                                                            And other miscellaneous expenses!
                                                        </li></ul>
                                                    It is recommended that you apply for a visa as early as possible! So hurry up and contact us to gain a hassle-free job seeker visa for Germany. <br /><br />

                                                    <h5 className='m-0'>
                                                        What are the eligibility criteria for a job seeker visa for Germany?
                                                    </h5>
                                                    <p>To get a job seeker visa for Germany, you may be required to meet several prerequisites:</p>
                                                    <ul>
                                                        <li>Education qualification </li>
                                                        <li>Relevant work experience  </li>
                                                        <li>Financial capability proof  </li>
                                                        <li>Health Insurance  </li>
                                                        <li>English and German language proficiency  </li>
                                                        <li>Intent to find employment  </li>
                                                        <li>Clean criminal record  </li>
                                                        <li>Valid passport  </li>
                                                        <li>Proof of professional experience  </li>
                                                        <li>CV or resume  </li>
                                                        <li>Cover Letter </li>
                                                        <li>Statement of Purpose (SOP)  </li>
                                                        <li>Letter of Recommendation (LOR)  </li>
                                                        <li>Recent passport-size photographs  </li>
                                                        <li>Certificate of previous working experience (if any) </li>
                                                        <li>All the degrees and educational qualification  </li>
                                                        <li>Blocked Account  </li>
                                                        <li>Personal status like Marriage Certificate, Birth Certificate, etc  </li>
                                                        <li>Formal obligation letter – Verpflichtungserklärung from a sponsor residing in Germany.  </li>
                                                        <li>A lawful passport with a minimum validity of one year was offered in the previous ten years upon your scheduled exit from ermany.
                                                        </li>
                                                    </ul>
                                                    <p>- all the above documents should be originally authentic and be translated into either English or German based on the requirements
                                                        To get the details of the updated and latest requirements and eligibility criteria, do conductors and get immediate full assistance.
                                                        <br/>
                                                        There are plenty of job opportunities in Germany that require highly qualified and skilled professionals. Indian students have a fair chance of finding suitable employment in Germany!
                                                    </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
